import { Card, Grid, Typography, Dialog, Button, FormControlLabel, Checkbox, TextField, Box, Alert, AlertTitle, ListItem, ListItemText, Switch, List, ListSubheader, DialogContent, IconButton, DialogTitle, Divider, Stepper, StepLabel, Step, Snackbar, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import api from '../../../redux/features/auth/axiosAuthHelper';
import dayjs from 'dayjs';
import { SmallTextEditField, SmallEditReadOnlyField, StatusSelectField, StatusReadOnlySelectField } from '../fields/fields';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import colorConfigs from '../../../configs/colorConfigs';
import { DataSetSessionStorageKey, DataSetTableParameterStorageService } from '../../../services/sessionStorage';
import CartesianGrid from '../graphs/CartesianGrid';
import FileUploadCard from './FileUploadCard';
import MuiAlert from '@mui/material/Alert';
import FileDownloadCard from './FileDownloadCard';
import sizeConfigs from '../../../configs/sizeConfigs';
import ResultsPage from '../../../pages/results/ResultsPage';
import DataSetResultsCard from './DataSetResultsCard';

interface DataSetDetailProps {
  dataset_id?: any | null;
  handleRefreshButton: () => void;
  handleBackButton: () => void;
  handleActiveToggle: () => void;
}

const DataSetDetailCard: React.FC<DataSetDetailProps> = ({dataset_id, handleRefreshButton, handleBackButton, handleActiveToggle}) => {
    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const [dataset, setDataSet] = useState<any>(null);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [datasetResults, setDatasetResults] = useState([]);
    const create = !dataset_id
    const resultsCardRef = React.useRef<{ clearSelectedResults: () => void } | null>(null);



    // Handle switch toggle
    const handleSwitchToggle = async (event: any) => {
        formik.setFieldValue('status', event.target.checked);
        await formik.submitForm();  // Submit form with only 'status'
    
        // Show success message in Snackbar
        setSnackbarMessage(event.target.checked ? "DataSet activated" : "DataSet deactivated");
        setIsSnackbarOpen(true);
    };
    
    const validationSchema = () => {
        return Yup.object().shape({

        });
    }

    // Fetch dataset data and point_model JSON data
    useEffect(() => {
        if (dataset_id) {
            const fetchDataSet = async () => { 
                try {
                    const url = `datasets/${dataset_id}/with_results`;
                    
                    const response = await api.get(url, {
                        headers: {
                            'Authorization': `Bearer ${authData.access}`
                        }
                    });
                    
                    const fetchedDataSet = response.data;
                    setDataSet(fetchedDataSet);
                    setDatasetResults(fetchedDataSet.results)

                } catch (error) {
                    console.error('Error fetching dataset', error);
                    setErrors(`No DataSet found with ID ${dataset_id}`)
                    setTimeout(() => {
                        handleBackButton()
                    }, 4000);
                }
            };
            fetchDataSet();    
        }
    }, [dataset_id]);
    

    const formik = useFormik({
        enableReinitialize: !create,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues:
            (!create && dataset) ? {
                id: dataset.id || '',
                name: dataset.name || '',
                description: dataset.description || '',
                user_id: dataset.user_id || '',
                organisation_id: dataset.organisation_id || '',
                result_ids: dataset.result_ids || '',
                results: dataset.results || [],
                date_created: dataset.date_created ? dayjs(dataset.date_created).format('YYYY-MM-DD HH:mm:ss') : null, // Cant edit - Set to null if no date
                status: dataset.status || ''
            }
            : {
                name: '',
                description: '',
                result_ids: [],
                status: 'ACTIVE'
            },
        validationSchema,
        onSubmit: async (values) => {


            const data = {
                name: values.name,
                description: values.description,
                result_ids: values.result_ids,
                status: values.status
            }

            const url = dataset ? `datasets/${dataset.id}` : `datasets/`
            const method = dataset ? 'patch' : 'post'

            console.log("data", data)
            console.log("url", url)
            console.log("method", method)
            try {
                await api ({
                    method,
                    url,
                    data: data,
                    headers: {
                        'Authorization': `Bearer ${authData.access}`,
                    },
                });
                setErrors(null);
                console.log("DataSet successfully updated")
                if (method === 'post') {
                    handleRefreshButton() // reset states and close popup
                }
                if (method === 'patch') {
                    setSnackbarMessage(`DataSet successfully updated. Dataset is now ${data.status}`);
                    setIsSnackbarOpen(true);
                    handleActiveToggle() // refetches new data without closing popup
                }
                const count = DataSetTableParameterStorageService.get(DataSetSessionStorageKey.count)
                DataSetTableParameterStorageService.set(DataSetSessionStorageKey.count, count + 1) // Updated count to not trigger refresh data button
            } catch(error: any) {
                console.error("Error updating dataset", error);

                if (error.response) {
                    console.log("Response data:", error.response.data);
                    console.log("Response status:", error.response.status);
                    console.log("Response headers:", error.response.headers);
                } else if (error.request) {
                    console.log("Request error, no response:", error.request);
                } else {
                    console.log("Error setting up request:", error.message);
                }


                if (error?.response?.status === 400) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
                } else if (error?.response?.status === 403) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${error.response.data[firstErrorItem]}`);
                } else{
                    const errorText = `Unknown error updating dataset`;
                    setErrors(errorText);
                    console.error(errorText, error);
                }
                // window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    });

    const steps = [
        'Name Dataset',
        'Describe Dataset',
        'Select Data',
        'Set Status',
        'Save',
    ];


    const status = [
        { label: "Active", value: "ACTIVE"},
        { label: "Inactive", value: "INACTIVE" },
        { label: "Pending", value: "PENDING" },
    ];


    const handleSelectedResultsChange = (result_ids: any[]) => {
      formik.setFieldValue('result_ids', result_ids)
    }

    const handleReset = () => {
        formik.resetForm(); 
        // Call the clearSelectedResults function in the child
        if (resultsCardRef.current) {
            resultsCardRef.current.clearSelectedResults();
        }
    
    }


    // Function to determine the current active step
    const getActiveStep = () => {
        if (!formik.values.name) return 0;
        if (!formik.values.description) return 1
        if (!formik.values.result_ids?.length) return 2;
        if (!formik.values.status) return 3;
        return 4; // All previous steps are complete, move to Save step
    };

    const activeStep = getActiveStep();
    const isSaveStep = activeStep === steps.length - 1;


    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>

            {/* Outer Container */}
            <Grid
                container
                direction="column"
                sx={{
                    m: 0,
                    minHeight: sizeConfigs.detailPage.dialogContent,
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around", // Ensures even vertical spacing
                    alignItems: "stretch", // Ensures grids stretch properly
                    overflowY: "auto",
                    px: { xs: 8, xl: 20 },
                }}
                >              
                        {/* item 1 */}
                        { create &&
                         <Grid item xs={12} md={2} sx={{pt: {xs: 3, lg: 4, xl: 6}, height: "auto", width: '100%'}}>
                          <Grid container sx={{ gap: 4, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item xs={12} md={3}>
                                    <Typography sx={{ fontSize: 36, fontWeight: "bold" }}>Create Dataset</Typography>
                                </Grid>
                                <Grid item xs={12} md={8} sx={{mr: {xs: -8, xl: -8}}}>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((label, index) => (
                                        <Step key={label} completed={index < activeStep}>
                                        <StepLabel
                                            sx={{
                                            '& .MuiStepIcon-root': {
                                                color: index < activeStep ? colorConfigs.buttons.active : undefined, // Green for completed
                                            },
                                            '& .MuiStepIcon-text': {
                                                fill: '#fff', // Optional: Adjust text color for contrast
                                            },
                                            }}
                                        >
                                            {label}
                                        </StepLabel>
                                        </Step>
                                    ))}
                                    </Stepper>
                                </Grid>
                           </Grid>
                           </Grid>
                        }   

                        {/* item 2  */}
                           <Grid item xs={12} md={2} sx={{pt: {xs: 3, lg: 4, xl: 6}, flexGrow: 1, height: "100%", width: '100%' }}>
                           
                           {errors && (
                                 <Alert severity="error" style={{ width: "100%%" }}>
                                    <AlertTitle>Error</AlertTitle>
                                    {errors}
                                </Alert>
                            )}

                                <Grid container spacing={ create ? 5 : 0} direction={{xs: 'column', sm: 'row', md: 'row', lg: 'row'}} sx={{ pr: {xs: 4, md: 0}, gap: 0, display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={2.5} sx={{}}>
                                        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Name</Typography>
                                        <Box sx={{pl: 1.5}}>
                                            <Field name="name" type="text" className="form-control" placeholder="Name" component={create ? SmallTextEditField : SmallEditReadOnlyField} style={{marginTop: 8}} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={!create ? 6 : 9} sx={{}}>
                                        <Typography sx={{ fontSize: 18, fontWeight: 'bold'}}>Description</Typography>
                                        <Box sx={{pl: 1.5}}>
                                            <Field name="description" type="text" className="form-control" placeholder="Description" component={create ? SmallTextEditField : SmallEditReadOnlyField} style={{marginTop: 8}} />
                                        </Box>
                                    </Grid>
                                    { !create  && 
                                    <Grid item xs={2.5} sx={{}}>
                                        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>ID</Typography>
                                        <Box sx={{pl: 1.5}}>
                                            <Field name="id" type="text" className="form-control" placeholder="ID" component={SmallEditReadOnlyField}  style={{marginTop: 8}} /> 
                                        </Box>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>

                        {/* item 3  */}
                            <Grid item xs={12}  sx={{pt: {xs: 3, lg: 4, xl: 6}, flexGrow: 1, height: "100%", width: '100%', }}>
                                    <DataSetResultsCard ref={resultsCardRef} handleSelectedResultsChange={handleSelectedResultsChange} datasetResults={datasetResults} create={create}/>
                            </Grid>

                        {/* item 4  */}
                            <Grid item xs={12}  sx={{pt: {xs: 4, lg: 6, xl: 10}, height: "auto",  mt: "auto", width: '100%', pb: 10 }}>
                               <Grid container justifyContent={'space-between'} sx={{ height: "100%", width: '100%'}}>    
                                    <Grid item xs={6} sx={{}}>
                                        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>Set Status</Typography>
                                        <Box sx={{pl: 1.5}}>
                                        <Field
                                                name="status"
                                                component={StatusSelectField}
                                                statusOptions={status}
                                            />
                                        </Box>
                                    </Grid>


                                    <>
                                        <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignSelf: "flex-end",
                                            gap: 2 // Add gap between buttons
                                        }}
                                    >       
                                     { create &&    
                                        <Button
                                            variant="outlined"
                                            onClick={handleReset} // Reset form values to initial
                                            sx={{
                                                textTransform: 'none',
                                                fontSize: 16,
                                                mt: { xs: 3, sm: 3, md: 3, lg: 0 },
                                                minWidth: '140px',
                                                maxWidth: '140px',
                                            }}
                                        >
                                            Reset
                                        </Button>  
                                    }       
                                        <Tooltip title={!isSaveStep ? "Complete all steps to Save" : ""}>
                                            <span>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    disabled={!isSaveStep}
                                                    sx={{
                                                        color: "white",
                                                        textTransform: 'none',
                                                        fontSize: 16,
                                                        mt: { xs: 3, sm: 3, md: 3, lg: 0 },
                                                        minWidth: '140px',
                                                        maxWidth: '140px',
                                                        '&.Mui-disabled': { color: "white", opacity: 0.5 },
                                                    }}
                                                >
                                                    Save Changes
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                    </>
                               </Grid>
                            </Grid>
                </Grid>
                <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={() => setIsSnackbarOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} sx={{ mb: '-10px' }} >
                    <MuiAlert onClose={() => setIsSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </form>
        </FormikProvider>
    );
};

export default DataSetDetailCard;