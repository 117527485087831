import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ControlCorrection,
  ImageItem,
  Strip,
  StripRegion,
} from "../CustomHooks";
import ControlCorrectionPopup from "./ControlCorrectionPopup";
import RegionSelectorCard, { ImageProfile } from "./RegionSelectorCard";
import ProfileChart from "../graphs/ProfileChart";
import { Point } from "../../../utils/math";

interface StripCardProps {
  image?: ImageItem;
  position: number;
  label: string;
  onStateChange: (state: Strip) => void;
  onError: (key: string, error?: string) => void;
  data?: Strip;
  controlLineCorrection: boolean;
}

const StripCard: React.FC<StripCardProps> = ({
  image,
  position,
  label,
  onStateChange,
  onError,
  data,
  controlLineCorrection,
}) => {
  const [stripCorners, setStripCorners] = useState<Point[]>([]);
  const [originalStripCorners, setOriginalStripCorners] = useState<Point[]>([]);
  const [stripRegions, setStripRegions] = useState<StripRegion[]>([]);
  const [initialRegions, setInitialRegions] = useState<StripRegion[]>([]);
  const [controlCorrection, setControlCorrection] = useState<
    ControlCorrection | undefined
  >(undefined);
  const [imageProfile, setImageProfile] = useState<ImageProfile | undefined>(
    undefined
  );

  const validateRegions = (
    regions: StripRegion[],
    handleError: (key: string, error?: string) => void,
    stripPosition: number
  ) => {
    // Count regions by category
    const testCount = regions.filter(
      (region) => region.category === "TEST"
    ).length;
    const baselineCount = regions.filter(
      (region) => region.category === "BASELINE"
    ).length;
    const controlCount = regions.filter(
      (region) => region.category === "CONTROL"
    ).length;

    // Validation errors
    const testError =
      testCount > 0 ? undefined : "At least one Test region is required.";
    const baselineError =
      baselineCount > 0
        ? undefined
        : "At least one Baseline region is required.";
    const controlError =
      controlCount <= 1 ? undefined : "At most one Control region is allowed.";

    // Report errors
    handleError(`strip-${stripPosition}-regions-test`, testError);
    handleError(`strip-${stripPosition}-regions-baseline`, baselineError);
    handleError(`strip-${stripPosition}-regions-control`, controlError);

    return testError || baselineError || controlError;
  };

  useEffect(() => {
    console.log("StripCard: Change on data:", position, controlCorrection);
    const imageError = imageProfile
      ? undefined
      : `Strip [${label}]: Profile not available`;
    onError(`strip-image-${position}`, imageError);

    const correctionError =
      !controlCorrection && controlLineCorrection
        ? `Strip [${label}]: Control correction not available`
        : undefined;
    onError(`strip-control-correction${position}`, correctionError);

    const regionErrors = validateRegions(stripRegions, onError, position);

    if (imageProfile && !correctionError && !regionErrors) {
      const data: Strip = {
        position,
        label,
        sample_rows: imageProfile.sample_rows,
        sample_cols: imageProfile.sample_cols,
        profile: imageProfile.profile,
        strip_corners: stripCorners,
        original_strip_corners: originalStripCorners,
        control_correction: controlLineCorrection
          ? controlCorrection
          : undefined,
        strip_regions: stripRegions,
      };
      onStateChange(data);
    }
  }, [
    position,
    label,
    imageProfile,
    stripCorners,
    originalStripCorners,
    stripRegions,
    controlCorrection,
    onStateChange,
  ]);

  useEffect(() => {
    if (data) {
      setStripCorners(data.strip_corners);
      setOriginalStripCorners(data.original_strip_corners);
      setInitialRegions(data.strip_regions);
      setControlCorrection(data.control_correction);
      setImageProfile({
        profile: data.profile,
        sample_rows: data.sample_rows,
        sample_cols: data.sample_cols,
      });
    }
  }, [data]);

  return (
    <>
      <Box
        sx={{
          p: 2,
          border: "1px solid",
          borderColor: "grey.300",
          borderRadius: 2,
          mb: 3, // margin-bottom
          boxShadow: 3,
          // flex: 1,
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            color: "#6987C9",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Assay Configuration
        </Typography>
        {controlLineCorrection && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // flex: 5,
              gap: "1rem",
              // width: "100%",
            }}
          >
            <div style={{ flex: 1 }}>
              <ControlCorrectionPopup
                data={controlCorrection}
                setData={setControlCorrection}
              />
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // flex: 5,
            gap: "1rem",
            width: "100%",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1}
            sx={{
              flex: 1, // Make the container flexible
              width: "100%", // Ensure it takes full width
            }}
          >
            {image && (
              <RegionSelectorCard
                imageUrl={image.image_url}
                homography={image.homography}
                setImageProfile={setImageProfile}
                setStripCorners={setStripCorners}
                setOriginalStripCorners={setOriginalStripCorners}
                originalStripCorners={originalStripCorners}
              />
            )}
            {imageProfile ? (
              <ProfileChart
                profile={imageProfile?.profile}
                position={position}
                setStripRegions={setStripRegions}
                initialRegions={initialRegions}
              />
            ) : (
              <Box>No profile data available</Box>
            )}
          </Box>
        </div>
      </Box>
    </>
  );
};

export default StripCard;
