import React, { useCallback, useEffect, useRef, useState } from "react";
import { CircularProgress, Typography, Box } from "@mui/material";
import api from "../../../redux/features/auth/axiosAuthHelper";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ImageItem } from "../CustomHooks";

interface ImageGridProps {
  setSelectedImage: (selectedImage: ImageItem | undefined) => void;
  selectedImage?: ImageItem;
  onDoubleClick?: () => void;
}

const ImageGrid: React.FC<ImageGridProps> = ({
  selectedImage,
  setSelectedImage,
  onDoubleClick,
}) => {
  const authData = useSelector((state: RootState) => state.auth);
  const [items, setItems] = useState<ImageItem[]>([]);
  const [nextPage, setNextPage] = useState<string | null>(
    "results/images?limit=15"
  );
  const [loading, setLoading] = useState(false);

  console.log("ImageGrid:", selectedImage);

  const fetchImages = useCallback(
    async (nextUrl: string | null, access: string | null) => {
      if (!nextUrl || loading || !access) return;
      setLoading(true);
      try {
        const response = await api.get(nextUrl, {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        });

        setItems((prevItems: ImageItem[]) => {
          const existingUrls = new Set(
            prevItems.map((item: ImageItem) => item.image_url)
          );
          const newItems = response.data.results.filter(
            (item: ImageItem) => !existingUrls.has(item.image_url)
          );
          return newItems.length ? [...prevItems, ...newItems] : prevItems;
        });

        setNextPage(response.data.next);
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    },
    [loading]
  );

  const observer = useRef<IntersectionObserver | null>(null);

  const lastImageRef = useCallback(
    async (node: HTMLDivElement) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      if (node) {
        observer.current = new IntersectionObserver(async (entries) => {
          if (entries[0].isIntersecting && nextPage) {
            await fetchImages(nextPage, authData.access);
          }
        });

        observer.current.observe(node);
      }
    },
    [loading, nextPage, authData.access, fetchImages]
  );

  const handleImageSelect = (image: ImageItem) => {
    setSelectedImage(image);
  };

  const handleImageDoubleClick = (image: ImageItem) => {
    setSelectedImage(image);
    if (onDoubleClick) onDoubleClick();
  };

  useEffect(() => {
    if (authData.isAuthenticated) {
      fetchImages(nextPage, authData.access);
    }
  }, [nextPage, authData.access]);

  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(200px, 1fr))",
          gap: 2,
        }}
      >
        {items.map((item, index) => (
          <Box
            key={item.image_url}
            ref={index === items.length - 1 ? lastImageRef : null}
            sx={{
              padding: 1,
              textAlign: "center",
            }}
          >
            <img
              src={item.image_url}
              alt={item.image_url}
              width="100%"
              style={{
                cursor: "pointer",
                border:
                  selectedImage?.image_url === item.image_url
                    ? "5px solid blue"
                    : "none",
              }}
              onClick={() => handleImageSelect(item)}
              onDoubleClick={() => handleImageDoubleClick(item)}
            />
            <Typography variant="body2" color="textSecondary">
              <strong>Result ID:</strong> {item.result_id}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Timestamp:</strong> {item.timestamp}
            </Typography>
            <Typography variant="body2" color="textSecondary" noWrap>
              <a
                href={`/results/${item.result_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open
              </a>
            </Typography>
          </Box>
        ))}
      </Box>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Box>
  );
};

export default ImageGrid;
