import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table'
import React, { useMemo } from 'react'
import colorConfigs from '../../../configs/colorConfigs';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ScienceIcon from '@mui/icons-material/Science';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

interface LastFiveResultsTableProps {
    authData: any;
    rows: any[];
}
  
  //nested data is ok, see accessorKeys in ColumnDef below
const LastFiveResultsTable: React.FC<LastFiveResultsTableProps> = ({ 
    authData,
    rows
}) => {
    const navigate = useNavigate()
    const columns: MRT_ColumnDef<any>[] = [
          {
            accessorKey: 'id', //access nested data with dot notation
            header: 'result',
            Header: () => (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                  Result<br />&nbsp;
                </div>
              ),
            Cell: ({ row }) => (
                <div>
                  <ReceiptLongOutlinedIcon fontSize='small'/> {row.original.id}
                </div>        
              ),
            size: 75,
          },
          {
            accessorKey: 'timestamp', //access nested data with dot notation
            header: 'Timestamp',
            Header: () => (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                  Timestamp<br />&nbsp;
                </div>
              ),
            Cell: ({ row }) => (
                <div>
                  <TimerOutlinedIcon fontSize='small'/>{dayjs(row.original.timestamp).format('YYYY-MM-DD HH:mm:ss')}
                </div>        
              ),
            size: 50,
          },
          {
            accessorKey: 'method_id', //normal accessorKey
            header: 'Method',
            Cell: ({ row }) => (
                <div>
                  <AssignmentTurnedInOutlinedIcon fontSize='small'/> {row.original.method_id} 
                </div>        
              ),
            size: 25,
          },
          {
            accessorKey: 'sample_id', //normal accessorKey
            header: 'Sample',
            Cell: ({ row }) => (
                <div>
                  <ScienceIcon fontSize='small' /> {row.original.sample_id}
                </div>        
              ),
            size: 25,
          },
        ]

  return (
    <div style={{width:580}}>
        <MaterialReactTable
        data={rows? rows : []}
        columns={columns}
        enableColumnActions
        enableGlobalFilter ={false}
        enableGlobalFilterModes={false}
        enableColumnFilterModes={false}
        enableFilters={false}
        enableColumnFilters={false}
        enableHiding={false}
        enableDensityToggle={false}
        initialState={{density: 'compact'}}
        enableFullScreenToggle={false}
        enableTopToolbar={false}
        muiTableHeadCellProps= {{
            //no useTheme hook needed, just use the `sx` prop with the theme callback
            sx: (theme) => ({
              color: theme.palette.text.secondary,
            }),
          }}
        
        displayColumnDefOptions={{
            'mrt-row-actions': {
            header: "Actions", // Renames Actions Column
            },
        }}

        muiTableBodyRowProps={({ row }) => ({
            onClick: (event) => {
                console.log(row.original);
                navigate(`/results/${row.original.id}`);
            },
            sx: (theme: any) => ({
                ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
                backgroundColor:
                row.index % 2 === 0
                    ? colorConfigs.tables.firstRow
                    : colorConfigs.tables.secondRow,
            }),
            })}
                
        />
    </div>
  )
}

export default LastFiveResultsTable

// "results": [
//     {
//         "id": "67587880f8c36269075baab8",
//         "organisation_id": 3,
//         "user_id": 2,
//         "timestamp": "2024-12-12T11:39:11.848826Z",
//         "sample_id": "255",
//         "method_id": null,
//         "housing_id": 10,
//         "test_configuration": {
//             "collection_mode": false
//         }
//     },
// ]