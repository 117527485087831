import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  TextField,
  MenuItem,
} from "@mui/material";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";

import { ControlCorrection } from "../CustomHooks";
import { TextEditField } from "../fields/fields";

interface CustomDialogProps {
  disabled?: boolean;
  setData: (value: ControlCorrection | undefined) => void;
  data: ControlCorrection | undefined;
}

const ControlCorrectionPopup: React.FC<CustomDialogProps> = ({
  disabled = false,
  data,
  setData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const initialValues = {
    extension: data?.extension || 50,
    trim: data?.trim,
    div_profile: data?.div_profile,
    step_search: data?.step_search,
    search_min: data?.search_min,
    search_max: data?.search_max,
    min_fit_percentage: data?.min_fit_percentage,
    search_method: data?.search_method || "Window",
  };

  const validationSchema = () =>
    Yup.object({
      extension: Yup.number().required("Required"),
      trim: Yup.number().required("Required"),
      div_profile: Yup.number().required("Required"),
      step_search: Yup.number().required("Required"),
      search_min: Yup.number().required("Required"),
      search_max: Yup.number().required("Required"),
      min_fit_percentage: Yup.number().required("Required"),
      search_method: Yup.string().required("Required"),
    });

  return (
    <>
      <Button
        disabled={disabled}
        variant="contained"
        onClick={openDialog}
        style={{ margin: "15px" }}
      >
        Control Correction
      </Button>

      <Dialog
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            closeDialog();
          }
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Control Correction</DialogTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values: ControlCorrection | undefined) => {
            setData(values);
            closeDialog();
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <DialogContent>
                {/* Yellow warning box */}
                <Alert severity="warning" style={{ marginBottom: "16px" }}>
                  This is an advanced feature recommended for use by Novarum DX
                </Alert>

                <div style={{ flexGrow: 0, width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      {/* Extension field */}
                      <Field
                        name="extension"
                        type="number"
                        className="form-control"
                        label="Extension"
                        component={TextEditField}
                        required
                      />
                      <Field
                        name="trim"
                        type="number"
                        className="form-control"
                        label="Trim"
                        component={TextEditField}
                        required
                      />
                      <Field
                        name="div_profile"
                        type="number"
                        className="form-control"
                        label="Div Profile"
                        component={TextEditField}
                        required
                      />
                      <Field
                        name="step_search"
                        type="number"
                        className="form-control"
                        label="Step Search"
                        component={TextEditField}
                        required
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <Field
                        name="search_min"
                        type="number"
                        className="form-control"
                        label="Search Min"
                        component={TextEditField}
                        required
                      />
                      <Field
                        name="search_max"
                        type="number"
                        className="form-control"
                        label="Search Max"
                        component={TextEditField}
                        required
                      />
                      <Field
                        name="min_fit_percentage"
                        type="number"
                        className="form-control"
                        label="Minimum Fit Percentage"
                        component={TextEditField}
                        required
                      />
                      <TextField
                        fullWidth
                        select
                        label="Search Method"
                        margin="normal"
                        name="search_method"
                        value={data?.search_method || "Window"}
                        required
                      >
                        <MenuItem key={0} value="Window">
                          Window
                        </MenuItem>
                      </TextField>
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={closeDialog}
                  color="primary"
                  style={{ margin: "10px" }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  style={{ margin: "10px" }}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default ControlCorrectionPopup;
