import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { Box } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import assets from '../../../assets';


const ResultSourceRadarGraph = () => {

    const series = [36, 57, 42]
    

    const options: ApexOptions = {
          chart: {
            type: 'pie',
            width: 380,
            toolbar:{
              show:false,
            },
          },
          labels:['iOS', 'Android', 'Reprocessed'],
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#111']
            },
            formatter: function (val, opts) {
              return opts.w.config.series[opts.seriesIndex] + ' results'
            },
            background: {
              enabled: true,
              foreColor: '#fff',
              borderWidth: 0
            }
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -30,
              },
            },
          },
          stroke:{
            colors:['#c0c0c0']
           },
          fill: {
            type: 'image',
            opacity: 0.85,
            image: {
               src: [assets.images.apple, assets.images.android, assets.images.reprocessed],
              width: 50,
              height: 50,

            },
          },
          xaxis: {
            categories: ['iOS', 'Android', 'Reprocessed'],
            labels:{
              show: true,
              style: {
                colors: ["#000","#000","#000"],
                fontSize: "14px",
                fontFamily: 'Arial',
                fontWeight: 'Bold',
              }
            },
          },
          tooltip: {
            y: {
              formatter: function (val:any) {
                return val
              }
            }
          }
        };

    

    return (
      <Box sx={{pl:6, pt:6}}>
        <div id="chart">
            <ReactApexChart 
            type="pie"
            options={options}
            series={series}
            height={500}
             />
          </div>
        <div id="html-dist"></div>
      </Box>
    );
  }

export default ResultSourceRadarGraph