import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Paper, Slide, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn } from '../../redux/features/auth/authService';
import SignInDialog from '../../components/common/SignInDialog';
import api from '../../redux/features/auth/axiosAuthHelper';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { SubscriptionSessionStorageKey, SubscriptionTableParameterStorageService } from '../../services/sessionStorage';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from '../../configs/colorConfigs';
import SubscriptionsTable from '../../components/common/tables/SubscriptionsTable';
import SubscriptionDetailCard from '../../components/common/cards/SubscriptionDetailCard';
import sizeConfigs from '../../configs/sizeConfigs';
import fontConfigs from '../../configs/fontConfigs';

type Props = {}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const SubscriptionManagementPage = (props: Props) => {

const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);
const [isLoading, setIsLoading] = useState<boolean>(false);
const [subscription, setSubscription] = useState<any>(null);
const [popup, setPopup] = useState<any>("");
const [needToRefreshData, setNeedToRefreshData] = useState<any>(false);

const [sorting, setSorting] = useState<any>(() => {
  return SubscriptionTableParameterStorageService.get(SubscriptionSessionStorageKey.sorting) || []
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return SubscriptionTableParameterStorageService.get(SubscriptionSessionStorageKey.columnVisibility) || {}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return SubscriptionTableParameterStorageService.get(SubscriptionSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return SubscriptionTableParameterStorageService.get(SubscriptionSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return SubscriptionTableParameterStorageService.get(SubscriptionSessionStorageKey.pagination) || { pageSize: 1000, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return SubscriptionTableParameterStorageService.get(SubscriptionSessionStorageKey.showSearch) || false
});

const [data, setData] = useState<any>(() => {
  return SubscriptionTableParameterStorageService.get(SubscriptionSessionStorageKey.data) || []
});

const [count, setCount] = useState<any>(() => {
  return SubscriptionTableParameterStorageService.get(SubscriptionSessionStorageKey.count) || ""
});

const handleSaveButton = () => {
  setSubscription("")
  fetchData()
  setPopup("")
}

const handleBackButton = () => {
  setSubscription("")
  setPopup("")
}

useEffect(() => {
  if (data.length < 1) {
    fetchData();
  } else {
    if (!needToRefreshData) {
    //   checkForNewData();
    }
  }
  setNeedToRefreshData(false)
}, [data]); 


const fetchData = () => {
  setIsLoading(true);
  api.get(`subscription-plans/`, {
    headers: {
    'Authorization': `Bearer ${authData.access}`
  }
})
  .then(res => {
    // Set Data
    setData(res.data.results);
    console.log("subscriptions", res.data.results)
    SubscriptionTableParameterStorageService.set(SubscriptionSessionStorageKey.data, res.data.results)
    SubscriptionTableParameterStorageService.set(SubscriptionSessionStorageKey.count, res.data.count)
    setIsLoading(false);
    setNeedToRefreshData(false)
  })
  .catch(err => {
    console.log(err)
  })
}

const checkForNewData = () => { 
  // If new data has been added then alert refresh data button
  api.get(`subscription-plans?limit=1`, { 
    headers: {
    'Authorization': `Bearer ${authData.access}`
  }
})
  .then(res => {
    // Set Data
    if (count && count != res.data.count) {
      console.log("count", count, res.data.count)
      // setNeedToRefreshData(true)
      fetchData()
    }
  })
  .catch(err => {
    console.log(err)
  })
}

const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 250, pageIndex: 0 })
  setSorting([])
}

  return authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated ? (
      <div style={{ height: '100%', overflowX: "hidden", maxWidth: `85vw`, minWidth: `85vw`, margin: "auto"}}>
    <div style={{ marginTop: "20px", display: "flex", height: "100%", width: "100%" }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          // justifyContent="center"
          sx={{m:0}}
        >
          <Grid item xs={3} sx={{m:0, p:0}}>
            <Box sx={{ 
                m:0, 
                p:2, 
                height: "100%", 
                overflowY: 'auto',
                '&::-webkit-scrollbar': { display: 'none' },
                msOverflowStyle: 'none', // Internet Explorer 10+
                scrollbarWidth: 'none', // Firefox
                marginRight: 6, 
                display: 'flex', 
                flexDirection: 'column', 
                overflowX: "hidden", 
                maxWidth: `85vw`, 
                minWidth: `85vw`}}>
                <Box sx={{boxShadow: 5, borderRadius: 1}}>
                <Box sx={{
                    boxShadow: 5, 
                    borderRadius: 1,
                    height: 'calc(100vh - 120px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}>                    <SubscriptionsTable
                      data={data}
                      isLoading={isLoading}
                      sorting={sorting}
                      columnVisibility={columnVisibility}
                      columnFilters={columnFilters}
                      pagination={pagination}
                      globalFilter={globalFilter}
                      showGlobalFilter= {showGlobalFilter}
                      setShowGlobalFilter={setShowGlobalFilter}
                      handleRemoveAllFilters={handleRemoveAllFilters}
                      setGlobalFilter={setGlobalFilter}
                      setSorting={setSorting}
                      setColumnVisibility={setColumnVisibility}
                      setColumnFilters={setColumnFilters}
                      setPagination={setPagination}
                      setSubscription={setSubscription}
                      fetchData={fetchData}
                      setPopup={setPopup}
                      needToRefreshData={needToRefreshData}
                    />
                  </Box>
                </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog
            open={(subscription && popup === "Edit") || popup === "Create"}
            fullScreen
            onClose={() => {setSubscription(""); setPopup("")}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
              height: '100vh',
              minHeight: '100vh',
            '& .MuiDialog-paper': {
              alignItems: 'center',
              justifyContent: 'center',
              p:0,
              height: '100vh',
              minHeight: '100vh'
            }
            }}
          >
            <AppBar
              onClick={() => {setSubscription(""); setPopup("")}}
              sx={{
                position: 'fixed',  
                top: 0,             
                height: sizeConfigs.detailPage.appbar.height,
                minHeight: sizeConfigs.detailPage.appbar.height,
                maxHeight: sizeConfigs.detailPage.appbar.height,
                zIndex: 1201,
              }}
            >                  
             <Toolbar
                sx={{
                  height: sizeConfigs.detailPage.appbar.height,
                  minHeight: sizeConfigs.detailPage.appbar.height,
                  maxHeight: sizeConfigs.detailPage.appbar.height,
                  padding: 0,
                  backgroundColor: colorConfigs.tables.headBg,
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                }}
              >   
                {popup &&
                <Typography
                    onClick={(e) => e.stopPropagation()} 
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: fontConfigs.detailPage.appbar.title,
                      fontWeight: "bold",
                      m: "auto",
                      mt: 0,
                      mb: 0,
                    }}
                  >
                     {`${popup} Subscription`}
                    </Typography>  
                }
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {setSubscription(""); setPopup("")}}
                  aria-label="close"
                  sx={{
                    position: 'absolute',
                    right: 8,
                    padding: 0,
                    fontSize: '18px',
                  }}                
                  >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent
                  sx={{
                    height: sizeConfigs.detailPage.dialogContent.height,
                    minHeight: sizeConfigs.detailPage.dialogContent.height,
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': { display: 'none' },
                    msOverflowStyle: 'none', // Internet Explorer 10+
                    scrollbarWidth: 'none', // Firefox
                    minWidth: '100vw',
                    p: 0,
                    m: 0,
                    backgroundColor: colorConfigs.sidebar.bg,
                    mt: sizeConfigs.detailPage.appbar.height,
                  }}
                > 
                {(popup) &&
                <SubscriptionDetailCard subscription={subscription} create={popup === "Create"} handleSaveButton={handleSaveButton} handleBackButton={handleBackButton}  />
                }
            </DialogContent>
        </Dialog>
    </div>
    
) : (null);
};

export default SubscriptionManagementPage;