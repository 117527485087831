import React from 'react';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineDot,
    TimelineContent,
    TimelineOppositeContent,
} from '@mui/lab';
import { Typography, Box, Tooltip, Card } from '@mui/material';
import dayjs from 'dayjs';
import colorConfigs from '../../../configs/colorConfigs';
import sizeConfigs from '../../../configs/sizeConfigs';

interface PMFTimelineProps {
    pmfStories: any[];
    handleDialogOpen: (story: any, index: any) => void
  }

const PMFTimeline: React.FC<PMFTimelineProps> = ({ pmfStories, handleDialogOpen}) => {

        return pmfStories.length > 0 ? (
            <Box
                sx={{
                    zIndex: 2,
                    minHeight: sizeConfigs.detailPage.dialogContent.timeline,
                    maxHeight: sizeConfigs.detailPage.dialogContent.timeline,
                    maxWidth: '100%',
                    overflowY: 'auto',
                    paddingBottom: 10,
                }}
            >
                <Timeline>
                {pmfStories.map((pmfStory, index) => (
                    <TimelineItem key={index} sx={{ borderRadius: 3, pr: {xs: 1, sm: 2, md: 2, lg: 16, xl: 20}, minHeight: '30vh', display: 'flex', justifyContent: 'center'}}>
                        {/* Opposite Content */}
                    <TimelineContent
                        onClick={() => handleDialogOpen(pmfStory,index)}
                        color="text.secondary"
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                minWidth: '200px',
                                maxWidth: '200px',
                                minHeight: '120px',
                                maxHeight: '120px',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 3,
                                border: 'solid 1px #ddd',
                                background: index % 2 === 0 ? "rgba(255, 255, 255, 0.4)" : "rgba(255, 255, 255, 0.4)",
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>Time</Typography>
                                <Typography sx={{ pl: 1, textAlign: 'end', fontSize: '18px' }}>
                                    {dayjs(pmfStory.timestamp).format('hh:mm:ss.SSS A')}
                                </Typography>
                                <Typography
                                    sx={{
                                        textAlign: 'end',
                                        pl: 1,
                                        fontSize: '18px',
                                        color: 'green'
                                    }}
                                >
                                    {(() => {
                                        const firstTimestamp = dayjs(pmfStories[0]?.timestamp);
                                        const currentTimestamp = dayjs(pmfStory.timestamp);
                                        const diffMs = currentTimestamp.diff(firstTimestamp); // Difference in milliseconds
                                        const diffSeconds = diffMs / 1000; // Convert to seconds
                                        if (!index) {
                                            return ' '
                                        }
                                        else if (diffSeconds < 60) {
                                            return `+ ${diffSeconds.toFixed(3)}s`;
                                        } else {
                                            const minutes = Math.floor(diffSeconds / 60);
                                            const seconds = (diffSeconds % 60).toFixed(3);
                                            return `+ ${minutes}m ${seconds}s`;
                                        }
                                    })()}
                                </Typography>
                            </Box>
                        </Box>
                    </TimelineContent>

                        {/* Timeline Separator */}
                        <TimelineSeparator>
                                <TimelineConnector sx={{ opacity: index < 1 ? 0 : 1  }} />
                                    <Box 
                                    onClick={() => handleDialogOpen(pmfStory,index)}
                                    sx={{
                                        cursor: 'pointer',
                                        width: 'auto',
                                        height: '40px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        color: 'white',
                                        backgroundColor: 'primary.main',
                                        paddingX: 2,
                                        paddingY: 0.5,
                                        borderRadius: 1,
                                        boxShadow: 1,
                                        my: 1
                                    }}>
                                    <Typography sx={{ textAlign: 'center', minWidth: '100px', fontWeight: 'bold', fontSize: '18px'}}>Frame {index + 1}</Typography>
                                </Box>
                                <TimelineConnector sx={{ opacity: index < pmfStories.length - 1  ? 1 : 0  }} />
                        </TimelineSeparator>

                        {/* Content */}
                        <TimelineContent
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Box 
                                onClick={() => handleDialogOpen(pmfStory,index)}
                                sx={{   
                                    cursor: 'pointer',
                                    minWidth: '200px',
                                    maxWidth: '200px',
                                    minHeight: '120px',
                                    maxHeight: '120px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',                              
                                     }}>
                            <Box
                                component="img"
                                src={pmfStory.image_url}
                                alt={`Thumbnail ${index}`}
                                sx={{          
                                      
                                    width: 'auto',
                                    height: '120px',
                                    maxHeight: '120px',
                                    maxWidth: '200px',
                                    borderRadius: 3,
                                    border: 'solid 1px #ddd',
                                    objectFit: 'cover',
                                }}
                            />
                            </Box>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </Box>
    ) : null
};

export default PMFTimeline;