import React from 'react';
import { Card, Button, Box, Typography } from '@mui/material';
import api from '../../../redux/features/auth/axiosAuthHelper';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

interface FileDownloadCardProps {
    url: any;
    fileUrl: string;
    authData: any;
}

const FileDownloadCard: React.FC<FileDownloadCardProps> = ({ url, fileUrl, authData }) => {
    // Extract the file name from the URL
    const fileName = url ? url.split('/').pop() : 'file';

    const handleDownload = async () => {
        try {
            const response = await api.get(fileUrl, {
                headers: {
                    'Authorization': `Bearer ${authData.access}`,
                },
                responseType: 'blob',
            });
    
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const downloadUrl = window.URL.createObjectURL(blob);
    
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = fileName; // Use the extracted file name
            document.body.appendChild(link);
            link.click();
            link.remove();
    
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    return (
        <Card sx={{ p: 1, height: '100%', width: '100%', minWidth: { xs: '140px', md: '160px', lg: '180px', xl: '220px' }, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
                <Box sx={{
                    minHeight: { xs: '90px', md: '110px', lg: '130px', xl: '170px' },
                    maxHeight: { xs: '90px', md: '110px', lg: '130px', xl: '170px' },
                    minWidth: { xs: '140px', md: '160px', lg: '180px', xl: '220px' },
                    maxWidth: { xs: '140px', md: '160px', lg: '180px', xl: '220px' },
                    overflowY: 'auto', display: 'flex', flexDirection: 'column',
                    justifyContent: 'space-between', mb: 1, alignItems: 'center'
                }}>
                    <FileDownloadOutlinedIcon sx={{ color: 'grey', opacity: url ? 1 : 0.3, fontSize: { xs: 40, md: 50, lg: 60, xl: 70 }, transition: 'all 0.3s ease' }} />
                    {url && (
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                fontSize: { xs: 12, md: 12, lg: 14, xl: 14 },
                                mb: 1,
                                color: 'green',
                                maxWidth: { xs: '120px', md: '150px', lg: '170px', xl: '200px' },
                                overflowWrap: 'break-word',  // Allows long words to wrap
                                whiteSpace: 'normal',        // Allows multiline wrapping
                                display: 'block',            // Ensures multiline text display
                                textAlign: 'center',         // Centers text for better readability
                                cursor: 'pointer',
                            }}
                            onClick={() => window.open(url, '_blank')}
                        >
                            {fileName}
                        </Typography>
                    )}
                </Box>           
            </Box>
            {/* <Button
                variant="contained"
                onClick={handleDownload}
                disabled={!url}
                sx={{
                    bottom: 5,
                    width: '90%',
                    textTransform: 'none',
                    mx: 1
                }}
            >
                Download
            </Button> */}
        </Card>
    );
};

export default FileDownloadCard;
