import { Alert, Box, AlertTitle, Snackbar, Button, Card, Divider, FormControlLabel, FormGroup, Grid, IconButton, Link, Paper, Switch, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { isLoggedIn, refreshToken } from '../../../redux/features/auth/authService';
import api from '../../../redux/features/auth/axiosAuthHelper';
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';
import { FormIsActiveSwitch, OrgIDTextField, FormUserFirstNameTextField, FormUserIDTextField, FormUserLastNameTextField, OrgNameTextField, OrgTierTextField } from '../../../components/common/fields/ProfileFeilds';
import * as Yup from "yup";
import EditIcon from '@mui/icons-material/Edit';
import MuiAlert from '@mui/material/Alert';
import { SyntheticEvent } from 'react';
import { SnackbarCloseReason } from '@mui/material';
import { OrganisationAdminUsersReadOnlyField, SubscriptionSelectField, TextEditField, TextEditReadOnlyField } from '../fields/fields';
import dayjs from 'dayjs';

const loginpage = "/login"

type Props = {}


const EditOrganisationCard = (props: Props) => {
const [open, setOpen] = useState(false);

const [allowEdit, setAllowEdit] = useState<boolean>(false)
const [errors, setErrors] = useState<any>(null)
const [initialValues, setInitialValues] = useState<any>("")
const [updatedValues, setUpdatedValues] = useState<any>("")
const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);
const [hasMadeChanges, setHasMadeChanges] = useState<any>(false)
const [organisation, setOrganisation] = useState<any>(null)

const handleSnackbarClose = (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
if (reason === 'clickaway') {
    return;
}
setOpen(false);
};
const handleAlertClose = (event: SyntheticEvent<Element, Event>) => {
setOpen(false);
};


const fetchData = () => {
    let url = `organisations/${authData.organisation}`
    api.get(url, { 
      headers: {
      'Authorization': `Bearer ${authData.access}`
    }
  })
    .then(res => {
      // Set Data
      console.log(res.data)
      setOrganisation(res.data)
    })
    .catch(err => {
      console.log(err)
    })
  }

useEffect(() => {
    fetchData()
},[authData])

const validationSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required("This field is required"), 
    });
}

const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues:
    (organisation) ? {
        id: organisation.id || '',
        name: organisation.name || '',
        email: organisation.email || '',
        tier: organisation.subscription_tier || 0,
        date_created: organisation.date_created ? dayjs(organisation.date_created).format('YYYY-MM-DD HH:mm:ss') : null,
        status: organisation.status || 'ACTIVE',
        active_standard_users_count: organisation.active_standard_users_count || 0,
        permitted_number_of_users: organisation.permitted_number_of_users || 0,
        can_create_new_users: organisation.can_create_new_users || false,
    }
    : {
        id: '',
        name: '',
        email: '',
        subscription: 0,
        tier: '',
        date_created: dayjs(),
        status: 'ACTIVE'
    },
    validationSchema,
    onSubmit: async (values) => {
        setOpen(false)
        const patch_data = {
                name: values.name,
            }

        try {
            await api.patch(`organisations/${formik.values.id}/`, patch_data, {
                headers: {
                    'Authorization': `Bearer ${authData.access}`
                }
            })
            setErrors(null);
            setOpen(!open)
            setAllowEdit(!allowEdit)
            console.log(`Organisation edited successfully.`);
        } catch (error: any) {
            if (error?.response?.status === 400) {
                const firstErrorItem = Object.keys(error.response.data)[0];
                setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
            } else {
                const errorText = `Unknown error 'updating' organisation`;
                setErrors(errorText);
                console.error(errorText, error);
            }
        }
    },
    });

        // Effect to set initial form values from subscription to check for form changes
        useEffect(() => {
            setTimeout(() => {
                setInitialValues(formik.initialValues);
                setUpdatedValues(formik.initialValues);
            }, 500);
            setHasMadeChanges(false);
        }, [authData.isFreeUser, open]);
      
        // Update the updatedValues state when formik values change
        useEffect(() => {
            setUpdatedValues(formik.values);
        }, [formik.values]);
      
        // Function to check if form has changed so the save button becomes active on edit
        const hasFormChanged = (flatModel: Record<string, any>, formValues: Record<string, any>): boolean => {
            for (const key in formValues) {
                if (key === "expiry_date") {
                    const formDate = dayjs(formValues[key]).format('YYYY-MM-DD');
                    const initialDate = dayjs(formValues[key]).format('YYYY-MM-DD');
                    if (formDate !== initialDate) {
                        console.log("Changed Date", formDate, initialDate)
                        return true;
                    }
                } else {
                    if (formValues[key]!= flatModel[key]) {
                        console.log("Changed Data", formValues[key], flatModel[key])
                        return true;
                    }
                }
            }
            return false;
        };
                
        // Use Effect to check if any changes ahve been made to the form on Edit
        useEffect(() => {
            if (authData.user) {
                if (Object.keys(initialValues).length > 0 && Object.keys(updatedValues).length > 0) {
                    const formChanged = hasFormChanged(initialValues, updatedValues);
                    setHasMadeChanges(formChanged);
                }
            }
        }, [updatedValues, initialValues, authData.user]);
      
      

        return (
            <Grid item xs={12} md={5} style={{ display: 'flex', flexDirection: 'column' }}>
              <Card
                sx={{
                  boxShadow: 10,
                  p: 2,
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormikProvider value={formik}>
                  <form
                    onSubmit={formik.handleSubmit}
                    style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                  >
                    <div style={{ flexGrow: 0 }}>
                      <Typography variant="h6" gutterBottom sx={{ color: '#6987C9', fontWeight: 'bold' }}>
                        My Organisation
                      </Typography>
                      {errors && (
                        <Alert severity="error" style={{ width: '100%' }}>
                          <AlertTitle>Error</AlertTitle>
                          {errors}
                        </Alert>
                      )}
                      <Field
                        name="id"
                        type="text"
                        className="form-control"
                        label="ID"
                        component={TextEditReadOnlyField}
                      />
                      <Field
                        name="name"
                        type="text"
                        className="form-control"
                        label="Organisation Name"
                        component={TextEditField}
                      />
                      <Field name="email" type="text" className="form-control" label="Organisation Email" component={TextEditField} />
                      <Field name="tier" type="text" className="form-control" label="Subscription" component={SubscriptionSelectField} />
                      <Field
                          name="users"
                          component={OrganisationAdminUsersReadOnlyField}
                          activeUsersCount={formik.values.active_standard_users_count}
                          permittedUsersCount={formik.values.permitted_number_of_users}
                          canCreateNewUsers={formik.values.can_create_new_users}
                          label="No of Users"
                      /> 
                    </div>
                    
                    {/* This div ensures the button stays at the bottom */}
                    <div
                      style={{
                        marginTop: 'auto',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={!hasMadeChanges}
                        sx={{
                          m: 1,
                          color: 'white',
                          textTransform: 'none',
                          fontSize: 16,
                          animation: hasMadeChanges ? 'growShrink 0.5s infinite alternate' : 'none',
                          '&.Mui-disabled': {
                            color: 'white',
                            opacity: 0.5,
                          },
                        }}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </form>
                </FormikProvider>
              </Card>
              <Snackbar open={open} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
                  Organisation successfully updated
                </MuiAlert>
              </Snackbar>
            </Grid>
          );
          
          
      
};

export default EditOrganisationCard;

