import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import api from "../../../redux/features/auth/axiosAuthHelper";
import { Field, FormikProvider, useFormik } from "formik";
import { FormSwitch } from "../../../components/common/fields/ProfileFeilds";
import * as Yup from "yup";
import { TextEditField, TextEditReadOnlyField } from "../fields/fields";
import { ImageItem, Method, Strip, useHousing } from "../CustomHooks";
import SignInDialog from "../SignInDialog";
import ResultSelectorCard from "./ResultSelectorCard";
import TabbedStripsCard from "./TabbedStripsCard";
import NotificationSnackbarCard, {
  parseErrors,
} from "./NotificationSnackbarCard";
import StepperCard from "./StepperCard";

const statusLabels: Record<string, string> = {
  IN_PROGRESS: "In Progress",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

interface MethodCardProps {
  methodId?: any | null;
  handleRefreshButton?: () => void;
  handleBackButton: () => void;
}

const MethodCard: React.FC<MethodCardProps> = ({
  methodId,
  handleRefreshButton,
  handleBackButton,
}) => {
  const navigate = useNavigate();

  const [errorRecord, setErrorRecord] = useState<Record<string, string>>({});
  const [errorQueue, setErrorQueue] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const authData = useSelector((state: RootState) => state.auth);
  const { housing, housingError } = useHousing();
  const [selectedImage, setSelectedImage] = useState<ImageItem | null>(null);
  const [strips, setStrips] = useState<Strip[]>([]);
  const [stripsAsRecord, setStripsAsRecord] = useState<Record<number, Strip>>(
    {}
  );
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = [
    "Describe It",
    "Select housing",
    "Load Test Image",
    "Configure Strips",
    "Save",
  ];

  const updateStripState = useCallback(
    (strip: Strip) => {
      console.log("Saving strip state: ", strip.position, strip);
      setStripsAsRecord((prev) => ({ ...prev, [strip.position]: strip }));
    },
    [setStripsAsRecord]
  );

  const handleStripError = useCallback(
    (key: string, error?: string) => {
      console.log("Setting error: ", key, error);
      if (error) {
        setErrorRecord((prev) => ({ ...prev, [key]: error }));
      } else {
        setErrorRecord((prevState) => {
          const { [key]: _, ...newState } = prevState; // Exclude the attribute
          return newState;
        });
      }
    },
    [setErrorRecord]
  );

  const handleChangeStatus = async () => {
    setErrorQueue([]);
    setSuccessMessage("");
    const url = `methods/${methodId}/${
      formik.values.status === statusLabels["ACTIVE"]
        ? "deactivate"
        : "activate"
    }/`;
    try {
      const result = await api.patch(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        }
      );
      if (formik.values.status === statusLabels["ACTIVE"]) {
        formik.setFieldValue("status", statusLabels["INACTIVE"]);
        setSuccessMessage("Method successfully deactivated");
        console.log("Method successfully deactivated");
      } else {
        formik.setFieldValue("status", statusLabels["ACTIVE"]);
        setSuccessMessage("Method successfully activated");
        console.log("Method successfully activated");
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        const errors = parseErrors(error.response.data);
        setErrorQueue(errors);
      } else {
        const errorText = `Unknown error ${
          formik.values.status === statusLabels["ACTIVE"]
            ? "deactivating"
            : "activating"
        } method`;
        setErrorQueue([errorText]);
        console.error(errorText, error.response);
      }
    }
  };

  const getStripsAsArray = (strips: Record<number, Strip>): Strip[] => {
    const orderedStrips = Object.values(strips).sort(
      (a, b) => a.position - b.position
    );
    return orderedStrips;
  };

  const getStripsAsRecord = (strips: Strip[]): Record<number, Strip> => {
    return strips.reduce((acc, strip) => {
      acc[strip.position] = strip;
      return acc;
    }, {} as Record<number, Strip>);
  };

  useEffect(() => {
    const fetchMethodData = async () => {
      if (!authData.isAuthenticated || housing.length === 0 || !methodId) {
        return;
      }

      try {
        const response = await api.get(`methods/${methodId}`, {
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        });

        const data: Method = response.data;
        formik.setFieldValue("id", methodId);
        formik.setFieldValue("housing", data.housing);
        formik.setFieldValue("test_name", data.test_name);
        formik.setFieldValue("description", data.description || "");
        formik.setFieldValue("version", data.version);
        formik.setFieldValue("status", statusLabels[data.status || ""]);
        formik.setFieldValue("flash_required", data.flash_required);
        formik.setFieldValue("collection_mode", data.collection_mode);
        formik.setFieldValue("initial_h_factor", data.initial_h_factor);
        formik.setFieldValue(
          "control_line_correction",
          data.control_line_correction
        );
        formik.setFieldValue("profile_alignment", data.profile_alignment);
        formik.setFieldValue("control_exposure", data.control_exposure);
        formik.setFieldValue(
          "profile_control_levels",
          data.profile_control_levels
            ? JSON.stringify(data.profile_control_levels)
            : ""
        );
        formik.setFieldValue(
          "polynomial_baseline_order",
          data.polynomial_baseline_order
        );
        formik.setFieldValue("frames_to_capture", data.frames_to_capture);
        formik.setFieldValue("control_baseline", data.control_baseline);
        formik.setFieldValue(
          "control_baseline_threshold",
          data.control_baseline_threshold
        );
        setSelectedImage(data.strip_image);
        setStrips(data.strips);
        setStripsAsRecord(getStripsAsRecord(data.strips));
      } catch (error) {
        console.error("Error fetching method", error);
        setErrorQueue([`No Method found with ID ${methodId}`]);
        setTimeout(() => {
          handleBackButton();
        }, 4000);
      }
    };

    fetchMethodData();
  }, [housing, authData.isAuthenticated, methodId]);

  const validationSchema = () => {
    return Yup.object().shape({
      test_name: Yup.string().required("This field is required"),
      description: Yup.string().required("This field is required"),
      housing: Yup.string().required("This field is required"),
      profile_control_levels: Yup.string()
        .test(
          "is-valid-integer-array",
          "Profile Control Levels must be a list of integers, e.g., [2, 3]",
          (value) => {
            if (!value) return true; // Optional field
            try {
              const parsed = JSON.parse(value);
              return (
                Array.isArray(parsed) &&
                parsed.length === 2 &&
                parsed.every((num) => Number.isInteger(num))
              );
            } catch {
              return false;
            }
          }
        )
        .optional(),
    });
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      id: "",
      housing: "",
      test_name: "",
      description: "",
      version: 1,
      status: statusLabels["IN_PROGRESS"],
      flash_required: true,
      collection_mode: false,
      initial_h_factor: 1.5,
      control_line_correction: false,
      profile_alignment: true,
      control_exposure: true,
      minimumProfileControlLevel: 100,
      maximumProfileControlLevel: 250,
      polynomial_baseline_order: 3,
      frames_to_capture: 10,
      control_baseline: true,
      control_baseline_threshold: 1.5, // optional
    },
    validationSchema,
    onSubmit: async (values) => {
      if (Object.keys(errorRecord).length > 0) {
        console.log("Error count:", Object.keys(errorRecord).length);
        const errors = Object.values(errorRecord);
        setErrorQueue(errors);
        return;
      }

      const stripsData = getStripsAsArray(stripsAsRecord);
      let data: Method = {
        housing: values.housing,
        test_name: values.test_name,
        description: values.description,
        flash_required: values.flash_required,
        collection_mode: values.collection_mode,
        frames_to_capture: values.frames_to_capture,
        strip_image: null,
        strips: [],
      };
      const extraData = {
        initial_h_factor: values.initial_h_factor,
        control_line_correction: values.control_line_correction,
        profile_alignment: values.profile_alignment,
        control_exposure: values.control_exposure,
        profile_control_levels: [
          values.minimumProfileControlLevel,
          values.maximumProfileControlLevel,
        ],
        polynomial_baseline_order: values.polynomial_baseline_order,
        frames_to_capture: values.frames_to_capture,
        control_baseline: values.control_baseline,
        control_baseline_threshold: values.control_baseline_threshold,
        strips: stripsData,
        strip_image: selectedImage,
      };
      if (!values.collection_mode) {
        data = { ...data, ...extraData };
      }
      const update = methodId && values.status === statusLabels["IN_PROGRESS"];
      const url = update ? `methods/${methodId}/` : "methods/";
      const method = update ? "patch" : "post";

      console.log("SEND DATA", data);

      setErrorQueue([]);
      setSuccessMessage("");

      try {
        const result = await api({
          method,
          url,
          data,
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        });
        if (handleRefreshButton) {
          handleRefreshButton();
        }
        setSuccessMessage(
          `Method successfully ${update ? "updated" : "created"}.`
        );
        console.log(`Method successfully ${update ? "updated" : "created"}.`);
        navigate("/lf_configuration/methods");
      } catch (error: any) {
        if (error?.response?.status === 400) {
          const errors = parseErrors(error.response.data);
          setErrorQueue(errors);
        } else {
          const errorText = `Unknown error ${
            update ? "updating" : "creating"
          } method`;
          setErrorQueue([errorText]);
          console.error(errorText, error.response);
        }
      }
    },
  });

  useEffect(() => {
    if (!methodId) {
      formik.setFieldValue(
        "frames_to_capture",
        formik.values.collection_mode ? 50 : 10
      );
    }
    if (formik.values.collection_mode) {
      setErrorRecord({});
    }
  }, [formik.values.collection_mode, methodId]);

  useEffect(() => {
    console.log("selectedImage changed:", selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    console.log("strips changed:", stripsAsRecord);
  }, [stripsAsRecord]);

  useEffect(() => {
    if (!formik.values.test_name || !formik.values.description) {
      setActiveStep(0);
    } else if (!formik.values.housing) {
      setActiveStep(1);
    } else if (!selectedImage) {
      setActiveStep(2);
    } else if (
      Object.keys(stripsAsRecord).length === 0 ||
      Object.values(errorRecord).length
    ) {
      setActiveStep(3);
    } else {
      setActiveStep(4);
    }
  }, [
    formik.values.test_name,
    formik.values.description,
    formik.values.housing,
    selectedImage,
    stripsAsRecord,
    errorRecord,
  ]);

  return authData.isAuthenticated == undefined ? (
    // isAuthenticated is undefined or null
    <Box sx={{ width: "100%" }}>
      <StepperCard steps={steps} activeStep={activeStep} title="" />
      <LinearProgress sx={{ zIndex: 1001 }} />
    </Box>
  ) : authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={false} />
  ) : housing.length === 0 ? (
    // clinics not loaded yet
    <Box sx={{ width: "100%" }}>
      <StepperCard steps={steps} activeStep={activeStep} title="" />
      <LinearProgress sx={{ zIndex: 1001 }} />
    </Box>
  ) : authData.isAuthenticated ? (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <StepperCard steps={steps} activeStep={activeStep} title="" />
      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ flexGrow: 0, width: "100%" }}>
            <Box
              sx={{
                p: 2,
                mb: 3, // margin-bottom
                mt: 1,
              }}
            >
              {/* Container for horizontally arranged fields */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                {/* Left side - name on top of version */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <Field
                    name="test_name"
                    type="text"
                    className="form-control"
                    label="Name"
                    component={TextEditField}
                    onChange={formik.handleChange}
                    required
                  />
                  <Field
                    name="version"
                    type="text"
                    className="form-control"
                    label="Version"
                    default="1"
                    component={TextEditReadOnlyField}
                    onChange={formik.handleChange}
                    disabled
                  />
                </div>

                {/* Right side - description as a text area */}
                <div style={{ flex: 2 }}>
                  <Field
                    name="description"
                    type="text"
                    className="form-control"
                    label="Description"
                    component={TextEditField}
                    multiline
                    rows={3} // Use rows for textarea
                    required
                    onChange={formik.handleChange}
                  />
                </div>

                <div style={{ flex: 1 }}>
                  <Field
                    name="id"
                    type="text"
                    className="form-control"
                    label="ID"
                    component={TextEditReadOnlyField}
                    onChange={formik.handleChange}
                    disabled
                  />
                  <Field
                    name="status"
                    type="text"
                    className="form-control"
                    label="Status"
                    component={TextEditReadOnlyField}
                    onChange={formik.handleChange}
                    disabled
                  />
                </div>

                <div style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    select
                    label="Housings"
                    margin="normal"
                    autoFocus
                    name="housing"
                    value={formik.values.housing}
                    onChange={formik.handleChange}
                    required
                  >
                    <MenuItem key={0} value={""}>
                      <em>&nbsp;</em>
                    </MenuItem>
                    {housing.map((h) => (
                      <MenuItem key={h.id} value={h.id}>
                        {h.name}
                      </MenuItem>
                    ))}
                  </TextField>

                  {housingError && (
                    <p style={{ color: "red" }}>{housingError}</p>
                  )}
                </div>
              </div>
            </Box>
          </div>
          <div style={{ flexGrow: 0, width: "100%" }}>
            {/* <div style={{ flex: 1 }}> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  mb: 3, // margin-bottom
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    color: "#6987C9",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Reader Configuration
                </Typography>

                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Data Collection
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        gap: "1rem",
                        // width: "100%",
                      }}
                    >
                      <Field
                        name="collection_mode"
                        type="checkbox"
                        label="Collection Mode"
                        component={FormSwitch}
                        visible={false}
                      />
                      <Field
                        name="frames_to_capture"
                        type="number"
                        label="Frames to Capture"
                        component={TextEditField}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>

                {/* Reader Parameters Section */}
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Reader Parameters
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        gap: "1rem",
                        // width: "100%",
                      }}
                    >
                      <Field
                        name="flash_required"
                        type="checkbox"
                        label="Flash Required"
                        component={FormSwitch}
                      />
                      {!formik.values.collection_mode && (
                        <>
                          <Field
                            name="control_exposure"
                            type="checkbox"
                            label="Control Exposure"
                            component={FormSwitch}
                          />
                          <Field
                            name="control_line_correction"
                            type="checkbox"
                            label="Control Line Correction"
                            component={FormSwitch}
                          />
                          <Field
                            name="profile_alignment"
                            type="checkbox"
                            label="Profile Alignment"
                            component={FormSwitch}
                          />

                          <Field
                            name="polynomial_baseline_order"
                            type="number"
                            label="Polynomial Baseline Order"
                            component={TextEditField}
                            onChange={formik.handleChange}
                          />
                          <Field
                            name="initial_h_factor"
                            type="number"
                            label="Initial H Scale Factor"
                            component={TextEditField}
                            onChange={formik.handleChange}
                          />
                          <Field
                            name="control_baseline"
                            type="checkbox"
                            label="Control Baseline"
                            component={FormSwitch}
                          />
                          {formik.values.control_baseline && (
                            <>
                              <Field
                                name="control_baseline_threshold"
                                type="number"
                                label="Control Baseline Threshold"
                                component={TextEditField}
                                onChange={formik.handleChange}
                              />
                              <Field
                                name="minimumProfileControlLevel"
                                type="number"
                                label="Minimum Profile Control Level"
                                component={TextEditField}
                                onChange={formik.handleChange}
                              />
                              <Field
                                name="maximumProfileControlLevel"
                                type="number"
                                label="Maximum Profile Control Level"
                                component={TextEditField}
                                onChange={formik.handleChange}
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {!formik.values.collection_mode ? (
                <div
                  style={{
                    flex: 5,
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                      border: "1px solid",
                      borderColor: "grey.300",
                      borderRadius: 2,
                      mr: 1, // margin-right
                      mb: 3, // margin-bottom
                      boxShadow: 3,
                      display: "flex", // Enable flexbox
                      flexDirection: "column", // Arrange items in a row
                      gap: "1rem", // Space between items
                    }}
                  >
                    <Box
                      sx={{
                        // p: 2,
                        // boxShadow: 3,
                        display: "flex", // Enable flexbox
                        flexDirection: "row", // Arrange items in a row
                        // gap: "1rem", // Space between items
                      }}
                    >
                      {/* Assay Configuration */}
                      <Box sx={{ p: 2, flex: 1 }}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            color: "#6987C9",
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          Assay Configuration
                        </Typography>
                      </Box>
                      {/* ResultSelectorCard */}
                      <Box sx={{ flex: 2 }}>
                        <ResultSelectorCard
                          onAccept={setSelectedImage || undefined}
                        />
                      </Box>
                    </Box>
                    <TabbedStripsCard
                      image={selectedImage || undefined}
                      onStateChange={updateStripState}
                      onError={handleStripError}
                      strips={strips}
                      controlLineCorrection={
                        formik.values.control_line_correction
                      }
                    />
                  </Box>
                </div>
              ) : (
                <FormButtons
                  methodId={methodId}
                  handleChangeStatus={handleChangeStatus}
                  methodStatus={formik.values.status}
                />
              )}
            </div>
          </div>
          {/* Keep the button at the bottom */}
          {!formik.values.collection_mode && (
            <FormButtons
              methodId={methodId}
              handleChangeStatus={handleChangeStatus}
              methodStatus={formik.values.status}
            />
          )}{" "}
          {/* Adjusted width */}
        </form>
      </FormikProvider>
      {/* </Card> */}
      <NotificationSnackbarCard
        errorQueue={errorQueue}
        successMessage={successMessage}
        setErrorQueue={setErrorQueue}
        setSuccessMessage={setSuccessMessage}
      />
    </Box>
  ) : null;
};

interface FormButtonsProps {
  methodId?: any | null;
  handleChangeStatus: () => void;
  methodStatus: string;
}

const FormButtons: React.FC<FormButtonsProps> = ({
  methodId,
  handleChangeStatus,
  methodStatus,
}) => {
  return (
    <Box
      sx={{
        display: "flex", // Default to horizontal alignment
        flexDirection: "row", // Ensure buttons are in a row
        justifyContent: "flex-end", // Align buttons to the right
        alignItems: "flex-start", // Align buttons to the top
        width: "100%",
        paddingRight: "20px",
        paddingBottom: "20px",
      }}
    >
      {methodId && (
        <Button
          variant="contained"
          onClick={handleChangeStatus}
          sx={{
            m: 1, // Spacing between buttons
            color: "white",
            textTransform: "none",
            fontSize: 16,
            animation: "growShrink 0.5s infinite alternate",
            "&.Mui-disabled": {
              color: "white",
              opacity: 0.5,
            },
          }}
        >
          {methodStatus === "ACTIVE" ? "Deactivate" : "Activate"}
        </Button>
      )}
      <Button
        type="submit"
        variant="contained"
        sx={{
          m: 1, // Spacing between buttons
          color: "white",
          textTransform: "none",
          fontSize: 16,
          animation: "growShrink 0.5s infinite alternate",
          "&.Mui-disabled": {
            color: "white",
            opacity: 0.5,
          },
        }}
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default MethodCard;
