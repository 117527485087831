

import { Card, Grid, Box, Button, Typography, InputLabel, Table, TableRow, TableCell, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Switch, Dialog, DialogContent, CircularProgress, Collapse, Alert, AlertTitle, LinearProgress } from '@mui/material';
import React, { Component, useEffect, useState, useRef } from 'react'
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import { Navigate, Link as routerLink } from "react-router-dom";
import api from '../../../redux/features/auth/axiosAuthHelper';
import dayjs, { Dayjs } from 'dayjs';
import colorConfigs from '../../../configs/colorConfigs';
import ResultDetailGraph from '../graphs/ResultDetailGraph';
import { useTheme } from '@mui/material/styles';
import { ResultDetailTableField } from '../fields/fields';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import Slider from "react-slick";
import fontConfigs from '../../../configs/fontConfigs';
import ResultDetailAccordion from '../accordions/ResultDetailAccordion';
import sizeConfigs from '../../../configs/sizeConfigs';


const assignColorsToStrips = (strips: any) => {
    return strips.map((strip: any, index: number) => ({
        ...strip,
        baseline_color: colorConfigs.result.meanBaseline,
        profile_color: colorConfigs.result.meanProfile,
        c_color: colorConfigs.result.cColor,
        t_lines: strip.t_lines.map((line: any, lineIndex: number) => ({
            ...line,
            t_color: colorConfigs.result.tColors[lineIndex % colorConfigs.result.tColors.length],
        })),
    }));
};

const initializeFrames = (strip: any) => {
    return strip.pmf_story.map((frame: any, frameIndex: number) => ({
        ...frame,
        baseline_color: colorConfigs.result.colorPairs[frameIndex % colorConfigs.result.colorPairs.length].baseline_color,
        profile_color: colorConfigs.result.colorPairs[frameIndex % colorConfigs.result.colorPairs.length].profile_color,
    }));
};

interface ResultDetailProps {
  handleBackButton: () => void;
  popup: any;
  result_id: any;
}

const ResultDetailCard: React.FC<ResultDetailProps> = ({ handleBackButton, popup, result_id}) => {

    const [graphData, setGraphData] = useState<any>({
        test_lines: [],
        cscore: '',
        cpeak: '',
        profile: [''],
        baseline: [''],
        c_color: '',
        baseline_color: '',
        profile_color: '', 
        profiles: [],
        baselines: [],
        meanProfileVisible: false,
    })
    const [errors, setErrors] = useState<string | null>(null);
    const [strips, setStrips] = useState<any[]>([])
    const [user, setUser] = useState<any>("")
    const authData = useSelector((state: RootState) => state.auth);
    const sliderRef = useRef<Slider>(null); // Reference for the Slider
    const [result, setResult] = useState<any>(null)
    const [expanded, setExpanded] = useState(''); // Default to 'resultInfo' open on render
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedStrip, setSelectedStrip] = useState({
        baseline: [],
        c_line: {
            score: null,
            peak_position: null
        },
        t_lines: [
            {t_color: '',}
        ],
        c_color: '',
        profile_color: '',
        baseline_color: '',
        main_image: "",
        mean_profile: [],
        pmf_story: [
            {
                baseline: [],
                full_image: "",
                homography: [],
                lux: null,
                orientation: [],
                pmf_status: null,
                profile: [],
                strip_image: "",
                strip_statuses: [],
                timestamp: ""
            }
        ]
    });
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0)
    const [openMainImagePopup, setOpenMainImagePopup] = useState(false);
    const [openStripImagePopup, setOpenStripImagePopup] = useState(false);
    const [selectedStripImage, setSelectedStripImage] = useState("");

    const [frames, setFrames] = useState<any[]>([]);
    // const [selectedFrame, setSelectedFrame] = useState({
    //     baseline: [],
    //     full_image: "",
    //     homography: [],
    //     lux: null,
    //     orientation: [],
    //     pmf_status: null,
    //     profile: [],
    //     strip_image: "",
    //     strip_statuses: [],
    //     timestamp: "",
    // });
    const [meanProfileVisible, setMeanProfileVisible] = useState(true);
    const [showAllFrames, setShowAllFrames] = useState(false);
    const [frameSwitches, setFrameSwitches] = useState<boolean[]>([]);
    const [showTable, setShowTable] = useState(false);
    const [showGraph, setShowGraph] = useState(false);

    // const resultDetailsRef = useRef<HTMLDivElement>(null);
    // const configurationRef = useRef<HTMLDivElement>(null);
    
    // // Separate state variables for each accordion
    // const [resultScrollable, setResultScrollable] = useState(false);
    // const [resultShowScrollBottom, setResultShowScrollBottom] = useState(false);
    
    // const [configScrollable, setConfigScrollable] = useState(false);
    // const [configShowScrollBottom, setConfigShowScrollBottom] = useState(false);
    
    // // Updated checkScroll function to handle different refs
    // const checkScroll = (ref: any, setShowScrollBottom: any) => {
    //     if (ref.current) {
    //         const { scrollTop, scrollHeight, clientHeight } = ref.current;
    //         setShowScrollBottom(scrollTop + clientHeight < scrollHeight);
    //     }
    // };
    
    // // Updated scrollContent function to scroll specific ref
    // const scrollContent = (ref: any, direction: any) => {
    //     ref.current?.scrollBy({
    //         top: direction === 'down' ? 600 : -600,
    //         behavior: 'smooth'
    //     });
    // };
    
    // // Set up scroll event listeners for each ref
    // useEffect(() => {
    //     const handleResultScroll = () => checkScroll(resultDetailsRef, setResultShowScrollBottom);
    
    //     if (resultDetailsRef.current) {
    //         // Delay initial check to ensure content is fully loaded
    //         setTimeout(handleResultScroll, 100);
    
    //         // Add scroll listener
    //         resultDetailsRef.current.addEventListener('scroll', handleResultScroll);
    //     }
    
    //     return () => {
    //         resultDetailsRef.current?.removeEventListener('scroll', handleResultScroll);
    //     };
    // }, [resultDetailsRef.current?.scrollHeight, resultDetailsRef.current?.clientHeight]);
    
    // useEffect(() => {
    //     const handleConfigScroll = () => checkScroll(configurationRef, setConfigShowScrollBottom);
    
    //     if (configurationRef.current) {
    //         // Delay initial check to ensure content is fully loaded
    //         setTimeout(handleConfigScroll, 100);
    
    //         // Add scroll listener
    //         configurationRef.current.addEventListener('scroll', handleConfigScroll);
    //     }
    
    //     return () => {
    //         configurationRef.current?.removeEventListener('scroll', handleConfigScroll);
    //     };
    // }, [configurationRef.current?.scrollHeight, configurationRef.current?.clientHeight]);
    


    // const handleAccordianChange = (panel: any) => (event: any, isExpanded: any) => {
    //     setExpanded(isExpanded ? panel : false);
    //   };

    const handleStripTabChange = (event: any, newValue: any) => {
        setSelectedTab(newValue); // Update selected tab index
        setSelectedImageIndex(newValue);
        setSelectedStrip(strips[newValue]);
        setMeanProfileVisible(true); // Turn on mean profile
        setShowAllFrames(false); // Turn off Show All switch
        setFrameSwitches(new Array(frames.length).fill(false)); // Reset all frame switches to off
    };

    useEffect(() => {
        // When selectedStrip or selectedTab changes, reset carousel to first image
        sliderRef.current?.slickGoTo(0); // Navigate the carousel to the first slide
    }, [selectedStrip, selectedTab]);

    const handleMainImageOpen = () => {
        setOpenMainImagePopup(true);
    };

    const handleMainImageClose = () => {
        setOpenMainImagePopup(false);
    };

    const handleStripImageOpen = (image:any) => {
        setSelectedStripImage(image); // Set the selected strip image source
        setOpenStripImagePopup(true);
    };

    const handleStripImageClose = () => {
        setOpenStripImagePopup(false);
    };

    const validationSchema = () => {
        return Yup.object().shape({
        });
    }

    // Fetch Result for Feedback
    useEffect(() => {
        const fetchResult = async () => { 
            try {
                const url = `results/${result_id}/formatted`
          
                const response = await api.get(url, {
                    headers: {
                        'Authorization': `Bearer ${authData.access}`
                    }
                });
                setResult(response.data);
                console.log("result", response.data)

                try {
                    const url = `users/${response?.data?.user_id}`
              
                    const res = await api.get(url, {
                        headers: {
                            'Authorization': `Bearer ${authData.access}`
                        }
                    });
                    setUser(res.data);
                    console.log("user", res.data)
                    
                    try {
                        const url = `results/views/${result_id}`
                  
                        const res = await api.put(url, null, {
                            headers: {
                                'Authorization': `Bearer ${authData.access}`
                            }
                        });
                        console.log("Updated recently viewed result: ", result_id)
                    } catch (error) {
                        console.error('Error fetching result', error);
                    }

                } catch (error) {
                    console.log(error)
                    console.log(`Cannot find User with id ${result.user_id}`)
                }

            } catch (error) {
                console.error('Error fetching result', error);
                setErrors(`No Result found with ID ${result_id}`)
                setTimeout(() => {
                    handleBackButton()
                }, 4000);
            }
          };
            fetchResult()    
        }, [result_id])

    const theme = useTheme();


    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            app_build: '',
            app_version: '',
            device_model: '',
            device_uuid: '',
            full_image: '',
            id: '',
            latitude: '',
            longitude: '',
            notes: '',
            organisation_id: '',
            os_version: '',
            sample_id: '',
            sample_type: '',

            strip_image_paths: [],
            // Test lines
            baseline: '',
            cline_score: '',
            cline_peak_position: '',
            cline_peak_area: '',
            // Lines
            t_lines: [],
            tline_1_score: '',
            tline_1_area: '',
            tline_1_peak_position: '',
            tline_1_ratio: '',
            tline_2_score: '',
            tline_2_area: '',
            tline_2_peak_position: '',
            tline_2_ratio: '',
            profile: '',

            timestamp: '',
            user_id: '',
            uuid: '',
            tc_area_ratio: '',

            // User Data
            result_user_id: '',
            user_first_name: '',
            user_last_name: '',
            user_email: '',
            user_is_active: '',
            user_organisation_id: '',
            user_organisation_name: '',
            user_user_type: '',
            user_subscription_tier: '',

            camera_macro_focus_required: false,
            collection_mode: false,
            control_baseline: false,
            control_baseline_threshold: '',
            control_exposure: false,
            control_line_correction: false,
            flash_required: false,
            frames_to_capture: '',
            pmf_initial_h_scale_factor: '',
            polynomial_baseline_order: '',
            profile_alignment: false,
            profile_control_levels: '',
            reader_resolution: [], 
            test_pmf_classifier_file: '',
            test_pmf_max_conformance_probability: '',
            test_pmf_min_conformance_probability: '',        
        },
        validationSchema,
        onSubmit: (values) => {
        },
    });

    // Set strips when result changes
    useEffect(() => {
        if (result) {
            const coloredStrips = assignColorsToStrips(result.test_strips);
            setStrips(coloredStrips);
        }
    }, [result]);

    // Set frames, selected strip, and related data when strips or selectedImageIndex changes
    useEffect(() => {
        if (strips.length > 0 && selectedImageIndex != null) {
            const selectedStrip = strips[selectedImageIndex];
            setSelectedStrip(selectedStrip);

            const framesWithColors = initializeFrames(selectedStrip);
            setFrames(framesWithColors);

            // Initialize frame switches based on frames count
            setFrameSwitches(new Array(frames.length).fill(false));

            setGraphData({
                test_lines: selectedStrip.t_lines,
                cscore: selectedStrip.c_line?.score || 0,
                cpeak: selectedStrip.c_line?.peak_position || 0,
                c_color: selectedStrip.c_color,
                profile: selectedStrip.mean_profile,
                baseline: selectedStrip.baseline,
                baseline_color: selectedStrip.baseline_color || colorConfigs.result.meanBaseline,
                profile_color: selectedStrip.profile_color || colorConfigs.result.meanProfile,
                profiles: [], // Initialize as empty
                baselines: [], // Initialize as empty
                meanProfileVisible: meanProfileVisible
            });

            // Test Strips
            formik.setFieldValue('cline_score', result.test_strips[selectedImageIndex].c_line.score.toFixed(2));
            formik.setFieldValue('cline_peak_position', result.test_strips[selectedImageIndex].c_line.peak_position.toFixed(2));
            formik.setFieldValue('cline_area', result.test_strips[selectedImageIndex].c_line.area?.toFixed(2));

            // Setting lines as an array to dynamically render these
            formik.setFieldValue('t_lines', result.test_strips[selectedImageIndex].t_lines);
            
            // Dynaically sets tline score, peak and tc_ratio
            result.test_strips[selectedImageIndex].t_lines.map((line:any, index:any, array:any[]) => {
                formik.setFieldValue(`tline_${array.length - index}_score`, parseFloat(line.score).toFixed(2));
                formik.setFieldValue(`tline_${array.length - index}_peak_position`, parseFloat(line.peak_position).toFixed(2));
                formik.setFieldValue(`tline_${array.length - index}_ratio`, parseFloat(line.tc_ratio).toFixed(2));
                formik.setFieldValue(`tline_${array.length - index}_area`, parseFloat(line.area)?.toFixed(2));
                const TCAreaRatio = parseFloat(line.area) / result.test_strips[selectedImageIndex].c_line.area
                formik.setFieldValue(`tline_${array.length - index}_tc_area_ratio`, TCAreaRatio.toFixed(2));

            })

        }
    }, [strips, selectedImageIndex]);


    // Update graphData whenever the switches change
    useEffect(() => {
        const dynamicProfiles: any[] = [];
        const dynamicBaselines: any[] = [];
    
        // Add mean profile and baseline if visible
        if (meanProfileVisible) {
            dynamicProfiles.push({ data: graphData.profile, color: graphData.profile_color, name: "Mean Profile" });
            dynamicBaselines.push({ data: graphData.baseline, color: graphData.baseline_color, name: "Mean Baseline" });
        }
    
        // Add frames data based on each frame switch
        frames.forEach((frame, index) => {
            if (frameSwitches[index]) {
                if (frame.profile) {
                    dynamicProfiles.push({ data: frame.profile, color: frame.profile_color, name: `Profile ${index + 1}` });
                }
                if (frame.baseline) {
                    dynamicBaselines.push({ data: frame.baseline, color: frame.baseline_color, name: `Baseline ${index + 1}` });
                }
            }
        });
    
        // Set graphData with updated profiles and baselines
        setGraphData((prev: any) => ({
            ...prev,
            meanProfileVisible: meanProfileVisible,
            profiles: dynamicProfiles,
            baselines: dynamicBaselines
        }));
        setTimeout(() => {
            setShowGraph(true)     
        }, 600);
        setTimeout(() => {
            setShowTable(true);  
        }, 1200);
        setTimeout(() => { 
            setExpanded('resultInfo')  
        }, 1800);
    }, [meanProfileVisible, frameSwitches]);
    

    // Set frame switches when Show All is toggled
    useEffect(() => {
        // Toggle all frames based on Show All switch
        if (showAllFrames) {
            setFrameSwitches(new Array(frames.length).fill(true)); // Turn on all frames
        } else {
            setFrameSwitches(new Array(frames.length).fill(false)); // Turn off all frames
        }
    }, [showAllFrames, frames.length]);

    const handleMeanProfileSwitch = () => {
        setMeanProfileVisible(prevState => !prevState);
    };

    const handleShowAllSwitch = () => {
        const newSwitches = new Array(frames.length).fill(!showAllFrames);
        setShowAllFrames(!showAllFrames);
        setFrameSwitches([...newSwitches]); // Ensuring a fresh array
    };

    const handleFrameSwitch = (index: number) => {
        setFrameSwitches(prevSwitches => {
            const newSwitches = [...prevSwitches];
            newSwitches[index] = !newSwitches[index];
            return newSwitches;
        });
    };


    useEffect(() => {
        if (result) {
                console.log("Result Data", result)

                formik.setFieldValue('app_build', result.app_build);
                formik.setFieldValue('app_version', result.app_version);
                formik.setFieldValue('device_model', result.device_model);
                formik.setFieldValue('device_uuid', result.device_uuid);
                formik.setFieldValue('id', result.id);
                formik.setFieldValue('latitude', result.latitude);
                formik.setFieldValue('longitude', result.longitude);
                formik.setFieldValue('notes', result.notes);
                formik.setFieldValue('organisation_id', result.organisation_id);
                formik.setFieldValue('os_version', result.os_version);
                formik.setFieldValue('sample_id', result.sample_id);
                formik.setFieldValue('sample_type', result.sample_type);
                formik.setFieldValue('method_id', result.method_id);
                formik.setFieldValue('housing_id', result.housing_id);


                formik.setFieldValue('camera_macro_focus_required', result.test_configuration.camera_macro_focus_required);
                formik.setFieldValue('collection_mode', result.test_configuration.collection_mode);
                formik.setFieldValue('control_baseline', result.test_configuration.control_baseline);
                formik.setFieldValue('control_baseline_threshold', result.test_configuration.control_baseline_threshold);
                formik.setFieldValue('control_exposure', result.test_configuration.control_exposure);
                formik.setFieldValue('control_line_correction', result.test_configuration.control_line_correction);
                formik.setFieldValue('flash_required', result.test_configuration.flash_required);
                formik.setFieldValue('frames_to_capture', result.test_configuration.frames_to_capture);
                formik.setFieldValue('pmf_initial_h_scale_factor', result.test_configuration.pmf_initial_h_scale_factor);
                formik.setFieldValue('polynomial_baseline_order', result.test_configuration.polynomial_baseline_order);
                formik.setFieldValue('profile_alignment', result.test_configuration.profile_alignment);
                formik.setFieldValue('profile_control_levels', result.test_configuration.profile_control_levels);
                formik.setFieldValue('reader_resolution', result.test_configuration.reader_resolution);
                formik.setFieldValue('test_pmf_max_conformance_probability', result.test_configuration.test_pmf_max_conformance_probability);
                formik.setFieldValue('test_pmf_min_conformance_probability', result.test_configuration.test_pmf_min_conformance_probability);

                formik.setFieldValue('timestamp', dayjs(result.timestamp).format('YYYY-MM-DD HH:mm:ss'));
                formik.setFieldValue('user_id', result.user_id)
                formik.setFieldValue('uuid', result.uuid)
    
                // Set User Data
                if (user) {
                    formik.setFieldValue('result_user_id', user.id);
                    formik.setFieldValue('user_first_name', user.first_name);
                    formik.setFieldValue('user_last_name', user.last_name);
                    formik.setFieldValue('user_email', user.email);
                    formik.setFieldValue('user_is_active', user.is_active);
                    formik.setFieldValue('user_organisation_name', user.organisation_name);
                    formik.setFieldValue('user_user_type', user.user_type);
                    formik.setFieldValue('user_subscription_tier', user.subscription_tier);
                }
            }
    }, [user, result]);


    // const handleJsonExport = async () => {
    //     const export_url = `results/${result.id}/export_json`;
    //     try {
    //         const response = await api.get(export_url, {
    //             headers: {
    //                 'Authorization': `Bearer ${authData.access}`
    //             },
    //             responseType: 'blob'
    //         });
    //         const url = window.URL.createObjectURL(response.data);
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.download = `result_${result.id}.json`;
    //         link.click();
    //         window.URL.revokeObjectURL(url);
    //     } catch (error) {
    //         console.error("Failed to download JSON file:", error);
    //     }
    // };

    return !result_id ? (
        <Navigate to={"/results"} />
    ) : !result ? (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box sx={{ width: "100%" }}>
             <LinearProgress />
        </Box>
        {errors && (
        <Alert severity="error" style={{ width: "100%%" }}>
            <AlertTitle>Error</AlertTitle>
            {errors}
        </Alert>
        )}
    </Box>
    ) : (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{height: '100%' }}>

            <Grid 
                container 
                sx={{ 
                    height: '100%',
                    minHeight: '100%',
                    display: 'flex', 
                    overflowY:{ md: 'auto', lg: 'hidden'}
                }}
                >            

                <ResultDetailAccordion result={result} authData={authData} expanded={expanded} setExpanded={setExpanded} user={user} formik={formik}/>

            {/* Right Panel */}
            <Grid 
                item 
                xs={12} 
                md={9} 
                lg={9} 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    height: '100%', 
                }}
                >
                    {/* Top Box For Tabs*/}
                    <Grid 
                        item 
                        sx={{ 
                        flex: '0 1 5%',
                        borderBottom: "solid #eee 1px", 
                        width: '100%',
                        background: 'rgba(1, 1, 1, 0.02)'
                        }}
                    >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Box sx={{ width: '100%' }}>
                        <Tabs
                            value={selectedTab}
                            onChange={handleStripTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="Scrollable tabs for strips"
                            indicatorColor="primary"
                            sx={{
                            width: '100%',
                            overflowX: 'auto',
                            maxWidth: '100%',
                            minHeight: sizeConfigs.detailPage.appbar.height,
                            maxHeight: sizeConfigs.detailPage.appbar.height,
                            '& .MuiTabs-scrollButtons': {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginX: 1,
                                '&.Mui-disabled': {
                                display: 'none', 
                                },
                                '&:not(.Mui-disabled)': {
                                display: 'flex',
                                },
                                '& .MuiIconButton-root': {
                                fontSize: '2rem',
                                fontWeight: 'bold',
                                '&:hover': {
                                    color: colorConfigs.primary,
                                },
                                },
                                '& .MuiSvgIcon-root': {
                                color: '#fff',
                                background: colorConfigs.primary,
                                borderRadius: '50%',
                                fontSize: '2rem',
                                '&:hover': {
                                    background: colorConfigs.sidebar.activeBg,
                                },
                                },
                            },
                            '& .MuiTabs-indicator': {
                                // backgroundColor: colorConfigs.primary,
                                // backgroundColor: 'transparent',
                            },
                            }}
                        >
                            {strips.map((strip, index) => (
                            <Tab 
                                key={index} 
                                label={`Strip ${index + 1}`}
                                disableRipple
                                sx={{
                                textTransform: 'none',
                                fontSize: fontConfigs.detailPage.headings.title,
                                fontWeight: 'bold',
                                minWidth: {sm: '60px', md: '70px', lg: '100px'},
                                minHeight: sizeConfigs.detailPage.appbar.height,
                                maxHeight: sizeConfigs.detailPage.appbar.height,
                                // color: ,
                                '&:hover': {
                                    color: colorConfigs.primary,
                                },
                                '&.Mui-selected': {
                                    color: colorConfigs.primary,
                                    fontWeight: 'bold',
                                },
                                }} 
                            />
                            ))}
                        </Tabs>
                        </Box>
                    </Box>
                    </Grid>

                    {/* Middle Container for Bottom Boxes */}
                    <Grid 
                        container 
                        sx={{ 
                        flex: '1 1 auto',
                        display: 'flex', 
                        flexDirection: 'row',
                        width: '100%',
                        height: '100%',
                        minHeight: sizeConfigs.detailPage.dialogContent.timeline,
                        maxHeight: sizeConfigs.detailPage.dialogContent.timeline,
                        }}
                    >

                    {/* Right Middle Box */}
                    <Grid 
                        item 
                        xs={12} 
                        md={9} 
                        lg={9.5} 
                        sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            flex: '1 1 auto',
                            overflowY: 'auto',
                            height: '100%',
                            minHeight: sizeConfigs.detailPage.dialogContent.timeline,
                            maxHeight: sizeConfigs.detailPage.dialogContent.timeline,
                            width: '100%',
                            py: 3,
                            '&::-webkit-scrollbar': { display: 'none' },
                            msOverflowStyle: 'none', // Internet Explorer 10+
                            scrollbarWidth: 'none', // Firefox
                        }}
                    >

                    <Grid container sx={{ p: 0, height: '50%' }}>
                        {/* Graph Container */}
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                            <Box sx={{ width: '65%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                                <ResultDetailGraph graphdata={graphData} showGraph={showGraph} />
                            </Box>
                        </Grid>


                    {/* Table Container */}
                    <Grid item xs={12} sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                        {/* <Collapse in={showTable && (graphData.profiles.length > 0 || meanProfileVisible)}> */}
                            <Box sx={{ height: '100%', width: '100%', maxWidth: '100%' }}>
                                <Table sx={{ width: '100%', height: 'auto' }}>
                                <tbody>
                                    {selectedStrip.t_lines.map((line, index) => (
                                        <TableRow key={index} sx={{ p: 0, m: 0}}>
                                            <TableCell sx={{ p: 0, m: 0}}>
                                                <Box sx={{mx: {xs: 1, sm: 1, md: 1, lg: 3}}}>
                                                    <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: "#fff", background: line.t_color, p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                                                        Test&nbsp;line&nbsp;{index + 1}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ p: 0, m: 0 }}>
                                                <Field name={`tline_${index + 1}_peak_position`} type="text" className="form-control" label="Position" component={ResultDetailTableField} color={line.t_color} />                                            
                                            </TableCell>
                                            <TableCell sx={{ p: 0, m: 0 }}>
                                                <Field name={`tline_${index + 1}_score`} type="text" className="form-control" label="Height" component={ResultDetailTableField} color={line.t_color} />
                                            </TableCell>
                                            <TableCell sx={{ p: 0, m: 0 }}>
                                                <Field name={`tline_${index + 1}_area`} type="text" className="form-control" label="Area" component={ResultDetailTableField} color={line.t_color} />
                                            </TableCell>
                                            <TableCell sx={{ p: 0, m: 0 }}>
                                                <Field name={`tline_${index + 1}_tc_area_ratio`} type="text" className="form-control" label="T/C Height" component={ResultDetailTableField} color={line.t_color} />
                                            </TableCell>
                                            <TableCell sx={{ p: 0, m: 0 }}>
                                                <Field name={`tline_${index + 1}_ratio`} type="text" className="form-control" label="T/C Area" component={ResultDetailTableField} color={line.t_color} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow sx={{ p: 0, m: 0 }}>
                                        <TableCell sx={{ p: 0, m: 0}}>
                                                <Box sx={{mx: {xs: 1, sm: 1, md: 1, lg: 3}}}>
                                                    <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: "#fff", background: selectedStrip.c_color, p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                                                        Control&nbsp;line
                                                    </Typography>
                                                </Box>
                                        </TableCell>
                                        <TableCell sx={{ p: 0, m: 0}}>
                                            <Field name="cline_peak_position" type="text" className="form-control" label="Position" component={ResultDetailTableField} color={selectedStrip.c_color} />                                            
                                        </TableCell>
                                        <TableCell sx={{ p: 0, m: 0 }}>
                                            <Field name="cline_score" type="text" className="form-control" label="Height" component={ResultDetailTableField} color={selectedStrip.c_color} />                                            
                                        </TableCell>
                                        <TableCell sx={{ p: 0, m: 0 }}>
                                            <Field name="cline_area" type="text" className="form-control" label="Area" component={ResultDetailTableField} color={selectedStrip.c_color} />                                            
                                        </TableCell>
                                        <TableCell sx={{ p: 0, m: 0 }}>-</TableCell>
                                        <TableCell sx={{ p: 0, m: 0 }}>-</TableCell>
                                    </TableRow>
                                </tbody>
                            </Table>
                        </Box>
                        {/* </Collapse> */}
                    </Grid>
                    </Grid>
                </Grid>

                {/* Left Middle Box */}
                <Grid 
                item 
                xs={12} 
                md={3} 
                lg={2.5} 
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 auto',       // Fills remaining vertical space
                    borderLeft: '1px solid #eee',
                    overflowY: 'auto',
                    minHeight: sizeConfigs.detailPage.dialogContent.timeline,
                    maxHeight: sizeConfigs.detailPage.dialogContent.timeline,
                    background: 'rgba(1, 1, 1, 0.02)',
                    '&::-webkit-scrollbar': { display: 'none' },
                    msOverflowStyle: 'none', // Internet Explorer 10+
                    scrollbarWidth: 'none', // Firefox
                }}
                >
                {/* Outer Box with full width and responsive height */}
                <Box 
                    sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    width: '100%', 
                    height: '100%',
                    flexGrow: 1 // Ensures the content grows to fill the Grid item
                    }}
                >

                    {/* Centered Image Box */}
                    <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        flexShrink: 0, // Prevents shrinking of the image box
                        
                    }}
                    >
                    {/* Image Container */}
                    <Box 
                        sx={{ 
                        width: '100%', 
                        cursor: 'pointer', 
                        p: 0.2, 
                        paddingY: 2, 
                        display: 'flex', 
                        justifyContent: 'center' 
                        }} 
                        onClick={handleMainImageOpen}
                    >
                        <Box
                        component="img"
                        src={selectedStrip.main_image}
                        alt="Full Image"
                        sx={{
                            width: { xs: '80%', md: '100%' },  // Responsive width
                            maxWidth: '100px',                 // Maximum width limit
                            height: 'auto',
                            objectFit: 'cover',
                            maxHeight: { md: '100%', xs: '300px' } // Height adjustment on mobile
                        }}
                        />
                    </Box>
                    {/* Dialog for the Image Popup */}
                    <Dialog
                        open={openMainImagePopup}
                        onClose={handleMainImageClose}
                        maxWidth="md"
                        fullWidth
                        slotProps={{ backdrop: { onClick: handleMainImageClose } }} // Close on clicking outside
                    >
                        <DialogContent sx={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box 
                            onClick={handleMainImageClose}
                            component="img" 
                            src={selectedStrip.main_image} 
                            alt="Main Image" 
                            sx={{ width: '100%', height: 'auto', objectFit: 'contain', cursor: 'pointer' }}
                        />
                        </DialogContent>
                    </Dialog>
                    </Box>

                    {/* Switches and Frames List */}
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.2, width: '100%', pl: {md: 0, lg: 2}, pr: {md: 2}, maxWidth: "80%" }}>
                    {/* Mean Profile Switch */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Switch 
                        checked={meanProfileVisible} 
                        onChange={handleMeanProfileSwitch} 
                        sx={{
                            color: meanProfileVisible ? selectedStrip.baseline_color : 'inherit',
                            '& .Mui-checked': {
                            color: selectedStrip.baseline_color,
                            },
                            '& .Mui-checked + .MuiSwitch-track': {
                            backgroundColor: selectedStrip.baseline_color,
                            },
                        }}
                        />
                        <Typography 
                        variant="body1" 
                        sx={{ 
                            color: meanProfileVisible ? colorConfigs.primary : 'inherit',
                            fontWeight: 'bold', 
                            marginRight: 2, 
                            minWidth: '100px',
                            fontSize: fontConfigs.detailPage.headings.frames,
                        }}
                        >
                        Mean Profile
                        </Typography>
                    </Box>

                    {/* Show All Switch */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Switch checked={showAllFrames} onChange={handleShowAllSwitch} />
                        <Typography variant="body1" sx={{fontSize: fontConfigs.detailPage.headings.frames, color: !showAllFrames ? 'inherit' : colorConfigs.primary, fontWeight: 'bold', marginRight: 2, minWidth: '100px' }}>
                        Show All Frames
                        </Typography>
                    </Box>

                    {/* Frames List */}
                    {frames.map((frame, index) => (
                        <Box 
                        key={index} 
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
                        >
                        <Switch 
                            key={`${index}-${frameSwitches[index]}`} // Unique key to force re-render
                            checked={frameSwitches[index]}
                            onChange={() => handleFrameSwitch(index)}
                            sx={{
                            color: frameSwitches[index] ? frame.profile_color : undefined, // Change switch color when on
                            '& .Mui-checked': {
                                color: frame.profile_color,
                            },
                            '& .Mui-checked + .MuiSwitch-track': {
                                backgroundColor: frame.profile_color,
                            }
                            }}
                        />
                        <Typography 
                            variant="body1" 
                            sx={{ 
                            color: frameSwitches[index] 
                            ? colorConfigs.primary 
                            : 'inherit',
                            fontWeight: 'bold', 
                            marginRight: 2,
                            fontSize: fontConfigs.detailPage.headings.frames,
                            }}
                        >
                            {index + 1}
                        </Typography>
                        <Box 
                            onClick={() => handleStripImageOpen(frame.strip_image)} 
                            component="img" 
                            src={frame.strip_image} 
                            alt={`Frame ${index + 1}`} 
                            sx={{ cursor: 'pointer', maxWidth: 100, borderRadius: '4px' }} 
                        />
                        </Box>
                    ))}
                    </Box>

                    {/* Dialog for Frame Image Popup */}
                    <Dialog
                    open={openStripImagePopup}
                    onClose={handleStripImageClose}
                    maxWidth="md"
                    fullWidth
                    slotProps={{ backdrop: { onClick: handleStripImageClose } }} // Close on clicking outside
                    >
                    <DialogContent sx={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box 
                        onClick={handleStripImageClose}
                        component="img" 
                        src={selectedStripImage} 
                        alt="Frame Image" 
                        sx={{ width: '100%', height: 'auto', objectFit: 'contain', cursor: 'pointer' }}
                        />
                    </DialogContent>
                    </Dialog>
                </Box>
                </Grid>



                </Grid>

            </Grid>
            </Grid>


            </form>
        </FormikProvider>
    )
}

export default ResultDetailCard;