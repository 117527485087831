import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import { Box } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';


const ResultSourceRadarGraph = () => {

    const series = [{
      name: 'Results',
      data: [36, 57, 42]
    }]
    

    const options: ApexOptions = {
          chart: {
            type: 'radar',
            height: 300,
            toolbar:{
              show:false,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: ['iOS', 'Android', 'Reprocessed'],
            labels:{
              show: true,
              style: {
                colors: ["#000","#000","#000"],
                fontSize: "14px",
                fontFamily: 'Arial',
                fontWeight: 'Bold',
              }
            },
          },
          tooltip: {
            y: {
              formatter: function (val:any) {
                return val
              }
            }
          }
        };

    

    return (
      <Box sx={{pl:4}}>
        <div id="chart">
            <ReactApexChart 
            type="radar"
            options={options}
            series={series}
            height={500}
             />
          </div>
        <div id="html-dist"></div>
      </Box>
    );
  }

export default ResultSourceRadarGraph