
import { Card, Grid, Box, Button, Typography, InputLabel, Table, TableRow, TableCell, Accordion, AccordionSummary, AccordionDetails, Tabs, Tab, Switch, Dialog, DialogContent, CircularProgress, Collapse, IconButton, Tooltip, AppBar, Toolbar, DialogActions, Slide } from '@mui/material';
import sizeConfigs from '../../../configs/sizeConfigs';
import fontConfigs from '../../../configs/fontConfigs';
import CloseIcon from '@mui/icons-material/Close';
import dayjs, { Dayjs } from 'dayjs';
import { TransitionProps } from '@mui/material/transitions';
import React, { Component, useEffect, useState, useRef } from 'react'
import colorConfigs from '../../../configs/colorConfigs';
import api from '../../../redux/features/auth/axiosAuthHelper';
import HomographyGraph from '../graphs/HomographyGraph';
import { Field, useFormik } from 'formik';
import { NotesField, ResultDetailEditField, SmallEditReadOnlyField } from '../fields/fields';
import * as Yup from "yup";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

interface ResultDetailDCMCardProps {
    handleDialogClose: () => void;
    setSelectedStory: (detail: any) => void;
    selectedStory: any;
    pmfStories: any[];
    dialogOpen: any;
    housing_id: any;
    authData: any;
    formik: any;
}

const ResultDetailDCMHomography: React.FC<ResultDetailDCMCardProps> = ({handleDialogClose, selectedStory, pmfStories, dialogOpen, housing_id, authData, formik, setSelectedStory }) => {
    const [housing, setHousing] = useState<any>(null);
    const [graphData, setGraphData] = useState<any>()
    const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('right');
    const [isTransitioning, setIsTransitioning] = useState(true); // Default to true for initial visibility
    const [disableButtons, setDisableButtons] = useState(false); // Default to true for initial visibility

    const handleChangeSelectedStory = (action: string) => {
        const currentIndex = pmfStories.indexOf(selectedStory);
    
        // Disable buttons during the transition
        setDisableButtons(true);
    
        // Determine the direction for sliding out
        if (action === "Next") {
            setSlideDirection('right'); // Slide out to the left for Next
        } else {
            setSlideDirection('left'); // Slide out to the right for Previous
        }
    
        // Start slide-out animation
        setIsTransitioning(false);
    
        // Wait for the slide-out animation to complete
        setTimeout(() => {
            // Update the story after sliding out
            if (action === "Next" && currentIndex < pmfStories.length - 1) {
                setSelectedStory(pmfStories[currentIndex + 1]);
            } else if (action === "Previous" && currentIndex > 0) {
                setSelectedStory(pmfStories[currentIndex - 1]);
            }
    
            // Reverse the slide direction for the new content to slide in
            if (action === "Next") {
                setSlideDirection('left'); // Slide in from the right
            } else {
                setSlideDirection('right'); // Slide in from the left
            }
    
            // Start slide-in animation
            setIsTransitioning(true);
    
            // Re-enable buttons after the slide-in animation completes
            setTimeout(() => {
                setDisableButtons(false);
            }, 300); // Match the Slide animation duration
        }, 300); // Match the Slide animation duration
    };
    

    

    // Fetch housing data and point_model JSON data
    useEffect(() => {
        if (housing_id) {
            const fetchHousing = async () => { 
                try {
                    const url = `housing/${housing_id}`;
                    
                    const response = await api.get(url, {
                        headers: {
                            'Authorization': `Bearer ${authData.access}`
                        }
                    });
                    
                    const fetchedHousing = response.data;
                    setHousing(fetchedHousing);

                    // If point_model URL exists, fetch the JSON data
                    if (fetchedHousing.point_model) {
                        const modelResponse = await fetch(fetchedHousing.point_model);
                        const modelData = await modelResponse.json();
                        setGraphData(modelData); // Set graphData for the chart
                    }

                } catch (error) {
                    console.error('Error fetching housing or point model data', error);
                }
            };
            fetchHousing();    
        }
    }, [housing_id]);

    // // Keeping This For Development Just Now
    // useEffect(() => {
    //     if (housing_id) {
    //         const fetchHousing = async () => { 
    //             try {
    //                 const url = `housing/${housing_id}`;
                    
    //                 const response = await api.get(url, {
    //                     headers: {
    //                         'Authorization': `Bearer ${authData.access}`
    //                     }
    //                 });
                    
    //                 const fetchedHousing = response.data;
    //                 setHousing(fetchedHousing);
    
    //                 // If point_model URL exists, ensure it has the correct port
    //                 if (fetchedHousing.point_model) {
    //                     const pointModelUrl = new URL(fetchedHousing.point_model);
    
    //                     // Check if port is missing, and add it if necessary
    //                     if (!pointModelUrl.port) {
    //                         pointModelUrl.port = '8000'; // Default to 8000 if no port is specified
    //                     }
    
    //                     // Fetch the JSON data from the modified URL
    //                     const modelResponse = await fetch(pointModelUrl.toString());
    //                     const modelData = await modelResponse.json();
    //                     setGraphData(modelData); // Set graphData for the chart
    //                 }

    //             } catch (error) {
    //                 console.error('Error fetching housing, point model, or image data', error);
    //             }
    //         };
    //         fetchHousing();    
    //     }
    // }, [housing_id]);
    

    return ( 

        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
                '& .MuiDialog-paper': {
                    m: 0, // No margin
                    width: 'auto', // Allow dynamic width based on content
                    maxWidth: 'md', // Set a maximum width (e.g., 'xs', 'sm', 'md', 'lg', 'xl', or a custom size)
                    height: 'auto', // Allow dynamic height based on content
                    maxHeight: '95vh', // Prevent it from exceeding the viewport height
                    overflow: 'auto', // Add scroll for overflowing content
                },
            }}
        >
        <AppBar
            sx={{
                position: 'relative',
                height: sizeConfigs.detailPage.appbar.height,
                minHeight: sizeConfigs.detailPage.appbar.height,
                maxHeight: sizeConfigs.detailPage.appbar.height,
            }}
        >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p:0, backgroundColor: colorConfigs.tables.headBg,}}>
                <Box onClick={(e) => e.stopPropagation()} sx={{ m: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'baseline'}}>
                    <Typography sx={{ fontSize: fontConfigs.detailPage.appbar.title, fontWeight: "bold", padding: "0 8px" }}>
                        Frame {pmfStories.indexOf(selectedStory) + 1}
                    </Typography>
                </Box>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleDialogClose}
                    aria-label="close"
                    sx={{ position: 'absolute', right: 15 }}
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
        <DialogContent sx={{ height: '100%', width: '100%', display: 'flex', p: 4 }}>
        {selectedStory && (
            <Slide
                in={isTransitioning} // Use the transitioning state
                timeout={300} // Match the delay in the handleChangeSelectedStory function
                mountOnEnter
                unmountOnExit
                direction={slideDirection} // Dynamic slide direction
            >
                <Box sx={{ height: '100%', width: '100%', display: 'flex'}}>
                    <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', pt: 1, pb: 3 }}>
                        <Field name="selected_story_date" type="text" className="form-control" label="Date" component={ResultDetailEditField} />
                        <Field name="selected_story_time" type="text" className="form-control" label="Time" component={ResultDetailEditField} />
                        <Field name="selected_story_difference" type="text" className="form-control" label="Time Difference" component={ResultDetailEditField} />
                        <Field name="selected_story_lux" type="text" className="form-control" label="Lux" component={ResultDetailEditField} />
                        <Field name="selected_story_pmf_status" type="text" className="form-control" label="PMF Status" component={ResultDetailEditField} />
                        <Field name="selected_story_orientation" type="text" className="form-control" label="Orientation" component={ResultDetailEditField} />
                        <Field name="selected_story_homography" type="text" className="form-control" label="Homography" component={NotesField} rows={6} />
                    </Box>
                    <Box sx={{ p: 3, height: '100%', width: '100%' }}>
                        <HomographyGraph graphdata={graphData} homography={selectedStory.homography} image={selectedStory.image_url} />
                    </Box>
                </Box>
            </Slide>
        )}
        </DialogContent>
        <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Button
                variant="contained"
                onClick={() => {
                    setSlideDirection('left'); // Slide left for Previous
                    handleChangeSelectedStory("Previous");
                }}
                sx={{
                    bottom: 5,
                    textTransform: 'none',
                    mx: 1,
                    px: 3
                }}
                disabled={
                    disableButtons || // Disable during transitions
                    pmfStories.indexOf(selectedStory) === 0 // Disable if on the first story
                }
            >
                Prev
            </Button>
            <Button 
                variant="contained" 
                onClick={handleDialogClose}
                sx={{
                    bottom: 5,
                    textTransform: 'none',
                    mx: 1
                }}
                >
                Close
            </Button>
            <Button 
                variant="contained" 
                onClick={() => {
                    setSlideDirection('right'); // Slide right for Next
                    handleChangeSelectedStory("Next");
                }}                
                sx={{
                    bottom: 5,
                    textTransform: 'none',
                    mx: 1,
                    px: 3
                }}
                disabled={
                    disableButtons || // Disable during transitions
                    pmfStories.indexOf(selectedStory) === pmfStories.length - 1 // Disable if on the last story
                }
                >
                Next
            </Button>
        </DialogActions>
    </Dialog>

     );
}
 
export default ResultDetailDCMHomography;