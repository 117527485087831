

import { Card, CardContent, LinearProgress, Grid, Box, Button, Link, makeStyles, Paper, Typography, FormControlLabel, Select, MenuItem, TextField, Checkbox, NativeSelect, OutlinedInput, FormControl, InputLabel, List, ListItem, Table, TableRow, TableCell, Collapse } from '@mui/material';
import React, { Component, useEffect, useState } from 'react'
import { Field, Form, Formik, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import api from '../../../redux/features/auth/axiosAuthHelper';
import dayjs from 'dayjs';
import assets from '../../../assets'
import colorConfigs from '../../../configs/colorConfigs';
import ResultDetailGraph from '../graphs/ResultDetailGraph';
import { useTheme } from '@mui/material/styles';
import { ResultDetailEditField, TCRatioField, ImageField, TScoreField, CScoreField, CPeakField, TPeakField, FormVisualResultsField, NotesField, TextAdornmentReadOnlyField, TextAdornmentNoStatusReadOnlyField, FeedbackReadOnlyField } from '../fields/fields';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
interface FeedbackDetailProps {
  feedback: any;
  handleBackButton: () => void;
}

interface LineData {
    score: number | string;
    peak_position: number | string;
    tc_ratio: number;
  }
  
  interface GraphData {
    test_lines: LineData[];
    cscore: number | string;
    cpeak: number | string;
    profile: string[];
    baseline: string[];
    quantitativeresult: number | string;
    thesholdmin: number | string;
    thesholdmax: number | string;
  }

const FeedbackDetailCard: React.FC<FeedbackDetailProps> = ({feedback, handleBackButton}) => {
    const [viewedResult, setViewedResult] = useState<any[]>([])
    const [graphData, setGraphData] = useState<GraphData>({
        test_lines: [],
        cscore: '',
        cpeak: '',
        profile: [''],
        baseline: [''],
        quantitativeresult: '',
        thesholdmin: '',
        thesholdmax: '', })
    const [testImage, setTestImage] = useState<any>()
    const [stripImages, setStripImages] = useState<any[]>([])
    const [lines, setLines] = useState<any[]>([])
    const [result, setResult] = useState<any>("")
    const [user, setUser] = useState<any>("")
    const [error, setError] = useState<any>("")
    const authData = useSelector((state: RootState) => state.auth);

    const validationSchema = () => {
        return Yup.object().shape({
        });
    }

    const theme = useTheme();


    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {

            // Result Data
            app_build: '',
            app_version: '',
            device_model: '',
            device_uuid: '',
            full_image: '',
            id: '',
            latitude: '',
            longitude: '',
            lot_number: '',
            notes: '',
            organisation_id: '',
            os_version: '',
            sample_id: '',
            sample_type: '',

            strip_image_paths: [],
            // Test lines
            baseline: '',
            cline_score: '',
            cline_peak_position: '',
            // Lines
            lines: [],
            tline_1_score: '',
            tline_1_peak_position: '',
            tline_1_ratio: '',
            tline_2_score: '',
            tline_2_peak_position: '',
            tline_2_ratio: '',
            profile: '',

            test_type: '',
            timestamp: '',
            user_id: '',
            uuid: '',
            visual_result: '',

            // Feedback Data 
            fb_reader_feedback: '',
            fb_test_feedback: '',
            fb_app_build: '',
            fb_app_version: '',
            fb_device_model: '',
            fb_device_uuid: '',
            fb_id: '',
            fb_lot_number: '',
            fb_organisation_id: '',
            fb_os_version: '',
            fb_result_uuid: '',
            fb_test_type: '',
            fb_timestamp: '',
            fb_user_id: '',

            // User Data
            fb_result_user_id: '',
            fb_user_first_name: '',
            fb_user_last_name: '',
            fb_user_email: '',
            fb_user_is_active: '',
            fb_user_organisation_name: '',
            fb_user_user_type: '',
            fb_user_subscription_tier: '',
        },
        validationSchema,
        onSubmit: (values) => {
        },
    });

    // Fetch Result for Feedback
    useEffect(() => {
        const fetchResult = () => {
            api.get(`results/?uuid=${feedback.result_uuid}`, { 
              headers: {
              'Authorization': `Bearer ${authData.access}`
            }
          })
            .then(res => {
              // Set Data
              setResult(res.data[0]);
              console.log("result", res.data)

            })
            .catch(err => {
              console.log(err)
              setError(`Cannot find result with uuid ${feedback.result_uuid}`)
            })
          }
          const fetchUser = () => {
            api.get(`users/${result.user_id}`, { 
              headers: {
              'Authorization': `Bearer ${authData.access}`
            }
          })
            .then(res => {
              // Set Data
              setUser(res.data);
              console.log("user", res.data)

            })
            .catch(err => {
              console.log(err)
              setError(`Cannot find User with id ${result.user_id}`)
            })
          }

          if (feedback){
            if (result) {
                fetchUser()
            } else {
                fetchResult()
            }
          }      
    }, [feedback, result])


    useEffect(() => {

                // Set Feedback Data
                formik.setFieldValue('fb_reader_feedback', feedback.reader_feedback);
                formik.setFieldValue('fb_test_feedback', feedback.test_feedback);
                formik.setFieldValue('fb_app_build', feedback.app_build);
                formik.setFieldValue('fb_app_version', feedback.app_version);
                formik.setFieldValue('fb_device_model', feedback.device_model);
                formik.setFieldValue('fb_device_uuid', feedback.device_uuid);
                formik.setFieldValue('fb_id', feedback.id);
                formik.setFieldValue('fb_lot_number', feedback.lot_number);
                formik.setFieldValue('fb_os_version', feedback.os_version);
                formik.setFieldValue('fb_organisation_id', feedback.organisation_id);
                formik.setFieldValue('fb_result_uuid', feedback.result_uuid);
                formik.setFieldValue('fb_test_type', feedback.test_type);
                formik.setFieldValue('fb_timestamp', dayjs(feedback.timestamp).format('YYYY-MM-DD HH:mm:ss'));
                formik.setFieldValue('fb_user_id', feedback.user_id);

                // Set User Data
                if (user) {
                    formik.setFieldValue('fb_result_user_id', user.id);
                    formik.setFieldValue('fb_user_first_name', user.first_name);
                    formik.setFieldValue('fb_user_last_name', user.last_name);
                    formik.setFieldValue('fb_user_email', user.email);
                    formik.setFieldValue('fb_user_is_active', user.is_active);
                    formik.setFieldValue('fb_user_organisation_name', user.organisation_name);
                    formik.setFieldValue('fb_user_user_type', user.user_type);
                    formik.setFieldValue('fb_user_subscription_tier', user.subscription_tier);
                }
   
                if (result){
                    console.log("Result Data", result)
                    setViewedResult(result)
                    setTestImage(result.full_image_path)
                    // setTestImage("https://pub.mdpi-res.com/biosensors/biosensors-11-00211/article_deploy/html/images/biosensors-11-00211-g001.png?1627912606")
    
                    console.log("Image", [result.full_image_path])
                    setGraphData({
                        test_lines: result.test_strips[0].lines,
                        cscore: result.test_strips[0].c_line.score,
                        cpeak: result.test_strips[0].c_line.peak_position,
                        profile: result.test_strips[0].profile,
                        baseline: result.test_strips[0].baseline,
                        quantitativeresult: 555, // DO WE NEED THIS
                        thesholdmin: 30,  // DO WE NEED THIS
                        thesholdmax: 1000,  // DO WE NEED THIS
                    })
    
                    formik.setFieldValue('app_build', result.app_build);
                    formik.setFieldValue('app_version', result.app_version);
                    formik.setFieldValue('device_model', result.device_model);
                    formik.setFieldValue('device_uuid', result.device_uuid);
                    formik.setFieldValue('full_image', result.full_image);
                    formik.setFieldValue('id', result.id);
                    formik.setFieldValue('latitude', result.latitude);
                    formik.setFieldValue('longitude', result.longitude);
                    formik.setFieldValue('lot_number', result.lot_number);
                    formik.setFieldValue('notes', result.notes);
                    formik.setFieldValue('organisation_id', result.organisation_id);
                    formik.setFieldValue('os_version', result.os_version);
                    formik.setFieldValue('sample_id', result.sample_id);
                    formik.setFieldValue('sample_type', result.sample_type);
    
                    // Test Strips
                    formik.setFieldValue('cline_score', parseFloat(result.test_strips[0].c_line.score).toFixed(2));
                    formik.setFieldValue('cline_peak_position', parseFloat(result.test_strips[0].c_line.peak_position).toFixed(2));
                    
                    // Setting lines as an array to dynamically render these
                    formik.setFieldValue('lines', result.test_strips[0].lines);
                    setLines(result.test_strips[0].lines)
                    
                    // Dynaically sets tline score, peak and tc_ratio
                    result.test_strips[0].lines.map((line:any, index:any, array:any[]) => {
                        formik.setFieldValue(`tline_${array.length - index}_score`, parseFloat(line.score).toFixed(2));
                        formik.setFieldValue(`tline_${array.length - index}_peak_position`, parseFloat(line.peak_position).toFixed(2));
                        formik.setFieldValue(`tline_${array.length - index}_ratio`, parseFloat(line.tc_ratio).toFixed(2));
                    })
                                   
                    // Setting strip_image_paths as an array to dynamically render these
                    formik.setFieldValue('strip_image_paths', result.strip_image_paths);
                    if (result.strip_image_paths) {
                        setStripImages([result.strip_image_paths])
                        // setStripImages(["https://pub.mdpi-res.com/biosensors/biosensors-11-00211/article_deploy/html/images/biosensors-11-00211-g001.png?1627912606","https://pub.mdpi-res.com/biosensors/biosensors-11-00211/article_deploy/html/images/biosensors-11-00211-g001.png?1627912606"])
                    }
    
    
                    formik.setFieldValue('test_type', result.test_type)
                    formik.setFieldValue('timestamp', dayjs(result.timestamp).format('YYYY-MM-DD HH:mm:ss'));
                    formik.setFieldValue('user_id', result.user_id)
                    formik.setFieldValue('uuid', result.uuid)
                    formik.setFieldValue('visual_result', result.visual_result)

                }
    
    }, [result, user]);

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{overflow: 'auto' }}>
            <Card sx={{height: "100%", boxShadow: 10, p:1, paddingTop: 0.5, marginX: 5, marginTop: 2, marginBottom: 3, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflowX: "auto"}}>

            {/* Feedback */}
            <div style={{width: "100%", height: "100%", margin: "auto", padding: "1em", display: "flex", alignItems: "center"}}>
                <Grid container spacing={3} wrap="nowrap" paddingTop="5px" alignItems="top" justifyContent="flex-start" style={{ minWidth: "100%" }}>
                    <Grid item xs={12} sm={6} md={4} style={{ display: "flex", flexDirection: "column", minWidth: "300px" }}>

                            <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                <div className="form-group">
                                    <Typography variant="body1" sx={{fontWeight: 'bold', color: colorConfigs.tables.headBg}}>User</Typography>
                                    {user ? 
                                    <Field name="fb_user_organisation_name" type="text" id={formik.values.fb_organisation_id} tier={formik.values.fb_user_subscription_tier} className="form-control" label="Organisation" component={TextAdornmentNoStatusReadOnlyField} />
                                    :
                                    <Field name="fb_organisation_id" type="text" className="form-control" label="Organisation ID" component={ResultDetailEditField} />
                                    }
                                    {user && 
                                    <div>
                                        <Field name="fb_user_email" type="text" id={formik.values.fb_result_user_id} status={formik.values.fb_user_is_active} className="form-control" label="User" component={TextAdornmentReadOnlyField} />
                                        <Field name="fb_user_user_type" type="text" className="form-control" label="User Type" component={ResultDetailEditField} />
                                        <div style={{display: "flex", justifyContent: "space-between", gap:"10px"}}>
                                            <Field name="fb_user_first_name" type="text" className="form-control" label="User First Name" component={ResultDetailEditField} />
                                            <Field name="fb_user_last_name" type="text" className="form-control" label="User Last Name" component={ResultDetailEditField} />
                                        </div>
                                    </div>
                                    }
                                </div>
                            </Card>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ minWidth: "200px", display: "flex", flexDirection: "column",  }}>
                            <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                            <Typography variant="body1" sx={{fontWeight: 'bold', color: colorConfigs.tables.headBg}}>Feedback</Typography>
                                <div className="form-group">
                                        <div style={{display: "flex", justifyContent: "space-between", gap:"10px"}}>
                                            <Field name="fb_reader_feedback" type="text" className="form-control" label="Reader Feedback" component={FeedbackReadOnlyField}  />
                                            <Field name="fb_test_feedback" type="text" className="form-control" label="Test Feedback" component={FeedbackReadOnlyField}  />
                                        </div>
                                            <Field name="fb_timestamp" type="text" className="form-control" label="Date" component={ResultDetailEditField}  />
                                            <Field name="fb_user_id" type="text" className="form-control" label="User Label" component={ResultDetailEditField} />
                                        <div style={{display: "flex", justifyContent: "space-between", gap:"10px"}}>
                                            <Field name="fb_lot_number" type="text" className="form-control" label="Lot #" component={ResultDetailEditField} />
                                            <Field name="fb_test_type" type="text" className="form-control" label="Test Type" component={ResultDetailEditField}  />
                                        </div>
                                </div>
                            </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} style={{ display: "flex", flexDirection: "column", minWidth: "300px" }}>
                            <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                            <Typography variant="body1" sx={{fontWeight: 'bold', color: colorConfigs.tables.headBg}}>Device</Typography>
                            <div className="form-group">
                            <Field name="fb_device_uuid" type="text" className="form-control" label="Device UUID" component={ResultDetailEditField} />
                                <div style={{display: "flex", justifyContent: "space-between", gap:"10px"}}>
                                    <Field name="fb_app_build" type="text" className="form-control" label="App Version" component={ResultDetailEditField} />
                                    <Field name="fb_app_version" type="text" className="form-control" label="App Version" component={ResultDetailEditField} />
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between", gap:"10px"}}>
                                    <Field name="fb_device_model" type="text" className="form-control" label="App Build" component={ResultDetailEditField} />
                                    <Field name="fb_os_version" type="text" className="form-control" label="OS Version" component={ResultDetailEditField}  />
                                </div>
                                </div>
                            </Card>
                    </Grid>
                </Grid>
            </div>


            <Card sx={{ boxShadow: 10, p:2, paddingX: 5, height: "100%", margin: "auto", mt: 4, background: colorConfigs.tables.headBg}}>
                {result ? 
                    <Typography textAlign='center' sx={{color: '#fff', fontSize: "20px", fontWeight: "bold",}}>Result {formik.values.id}</Typography>
                    :
                    <Typography textAlign='center' sx={{color: '#fff', fontSize: "20px", fontWeight: "bold",}}>No result found with UUID {feedback.result_uuid}</Typography>
                }
            </Card>

            {/* Result */}
            <Collapse in={!!result} timeout="auto">
            <div style={{width: "100%", height: "100%", margin: "auto", padding: "1em", display: "flex", alignItems: "center"}}>
                <Grid container spacing={3} wrap="nowrap" paddingTop="5px" alignItems="top" justifyContent="flex-start" style={{ minWidth: "100%" }}>
                    <Grid item xs={12} sm={6} md={3.5} style={{ display: "flex", flexDirection: "column", minWidth: "300px" }}>

                            <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                <div className="form-group">
                                    <Field name="timestamp" type="text" className="form-control" label="Date" component={ResultDetailEditField}  />
                                    {user ? 
                                    <div>
                                        <Field name="fb_user_email" type="text" id={formik.values.fb_result_user_id} status={formik.values.fb_user_is_active} className="form-control" label="User" component={TextAdornmentReadOnlyField} />
                                        <Field name="fb_user_organisation_name" type="text" id={formik.values.organisation_id}  tier={formik.values.fb_user_subscription_tier}  className="form-control" label="Organisation" component={TextAdornmentNoStatusReadOnlyField} />
                                    </div>
                                    :
                                    <Field name="user_id" type="text" className="form-control" label="User ID" component={ResultDetailEditField} />
                                    }
                                    <Field name="lot_number" type="text" className="form-control" label="Lot #" component={ResultDetailEditField} />
                                    <Field name="test_type" type="text" className="form-control" label="Test Type" component={ResultDetailEditField}  />
                                    <div style={{display: "flex", justifyContent: "space-between", gap:"10px"}}>
                                        <Field name="sample_id" type="text" className="form-control" label="Sample ID" component={ResultDetailEditField} />
                                        <Field name="sample_type" type="text" className="form-control" label="Sample Type" component={ResultDetailEditField} />   
                                    </div>
                                </div>
                            </Card>
                    </Grid>
                    <Grid item xs={12} md={5} style={{ minWidth: "590px", display: "flex", flexDirection: "column",  }}>
                            <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <div style={{display: "flex", gap: "20px", width: "540px"}}>
                                <Field name="nothing" visualResult={formik.values.visual_result} resultColor={colorConfigs.buttons.bg} type="text" className="form-control" label="Result Value" component={FormVisualResultsField} />
                                <Field name="nothing" image={testImage} type="text" className="form-control" label="Test image" component={ImageField} />
                                </div>
                                {/* <ResultDetailGraph graphdata = {graphData}/> */}
                            </div>
                            <div style={{width: "100%", marginTop: 8, display: "flex", justifyContent: "center"}}>
                            <Table sx={{width: "100%", align: "center"}} >
                            {lines.map((line, index) => {
                              return (
                                <TableRow key={index} sx={{p: 0, m:0}}>
                                    <TableCell sx={{p: 0, m:0}}>                                    
                                        <InputLabel
                                            sx={{
                                                paddingX: 1,
                                                paddingTop: 4,
                                                fontSize: 22,
                                                transform: 'translate(0, -1.5px) scale(0.75)', // Mimic shrunken transformation
                                                transformOrigin: 'top left',                                            
                                                fontWeight: 1000,                                          
                                            }}
                                        >
                                            Test line {index + 1}
                                        </InputLabel>
                                    </TableCell>
                                    <TableCell sx={{p: 0, m:0}}><Field name={`tline_${index + 1}_score`} type="text" className="form-control" component={TScoreField} /></TableCell>
                                    <TableCell sx={{p: 0, m:0}}><Field name={`tline_${index + 1}_peak_position`} type="text" className="form-control" component={TPeakField} /></TableCell>
                                    <TableCell sx={{p: 0, m:0}}><Field name={`tline_${index + 1}_ratio`} type="text" className="form-control" component={TCRatioField} /></TableCell>
                                </TableRow>      
                              )
                            })}
                                <TableRow sx={{p: 0, m:0}}>
                                    <TableCell sx={{p: 0, m:0}}>                                    
                                        <InputLabel
                                            sx={{
                                                paddingX: 1,
                                                paddingTop: 4,
                                                fontSize: 22,
                                                transform: 'translate(0, -1.5px) scale(0.75)', // Mimic shrunken transformation
                                                transformOrigin: 'top left',                                            
                                                fontWeight: 1000,  
                                            }}
                                        >
                                            Control line
                                        </InputLabel>
                                    </TableCell>                                    
                                    <TableCell sx={{p: 0, m:0}}><Field name="cline_score" type="text" className="form-control" component={CScoreField} /></TableCell>
                                    <TableCell sx={{p: 0, m:0}}><Field name="cline_peak_position" type="text" className="form-control" component={CPeakField} /></TableCell>
                                    <TableCell sx={{p: 0, m:0}}></TableCell>
                                </TableRow>
                            </Table>
                            </div>
                            </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3.5} style={{ display: "flex", flexDirection: "column", minWidth: "300px" }}>
                            <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>

                            <div className="form-group">
                                {stripImages.map((image, index) => {
                                   const title = `Test strip image ${index +1}`
                                   return (
                                    <Field key={index} name="nothing" image={image} type="text" className="form-control" label={title} component={ImageField} />
                                   )
                                })}
                                <Field name="notes" type="text" className="form-control" label="Notes" component={NotesField} rows={3} />
                                <Field name="app_version" type="text" className="form-control" label="App Version" component={ResultDetailEditField} />
                                <div style={{display: "flex", justifyContent: "space-between", gap:"10px"}}>
                                    <Field name="app_build" type="text" className="form-control" label="App Build" component={ResultDetailEditField} />
                                    <Field name="os_version" type="text" className="form-control" label="OS Version" component={ResultDetailEditField}  />
                                </div>
                                </div>
                            </Card>
                    </Grid>
                </Grid>
            </div>
            </Collapse>
            <div className="form-group" style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <Button
                            className="form-group"
                            onClick={() => handleBackButton()}
                            variant="contained"
                            sx={{
                                position: "relative",
                                m: 1,
                                background: colorConfigs.buttons.bg,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                        >
                            Back
                        </Button>
                    </div>
            </Card>
            </form>
        </FormikProvider>
    )
}

export default FeedbackDetailCard;