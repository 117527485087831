import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Button, Grid, Link, Typography, ListItemIcon, Menu, MenuItem, MenuProps, Paper, alpha, styled, Theme, Switch } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { ResultSessionStorageKey, ResultSessionStorageModel, ResultTableParameterStorageService } from "../../../services/sessionStorage";
import colorConfigs from "../../../configs/colorConfigs";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { tsXLXS } from 'ts-xlsx-export';
import CabinOutlinedIcon from '@mui/icons-material/CabinOutlined';
import { MRT_FilterFns } from 'material-react-table';

const StyledMenu = styled((props: any) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface HousingTableProps {
    authData: any;
    rows: any[];
    isLoading: boolean;
    sorting: any;
    columnVisibility: any;
    columnFilters: any;
    pagination: any;
    globalFilter: any;
    showGlobalFilter: any;
    setShowGlobalFilter: (value: any) => void;
    handleRemoveAllFilters: () => void;
    setGlobalFilter: (value: string) => void;
    setSorting: (sorting: any[]) => void;
    setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
    setColumnFilters: (filters: any[]) => void;
    setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
    setHousing: (detail: any) => void;
    setPopup: (detail: any) => void;
    handleRefreshButton: () => void;
  }

const HousingTable: React.FC<HousingTableProps> = ({
  authData,
  rows,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  setHousing,
  setPopup,
  handleRefreshButton
}) => {

    console.log("Housings", rows)
  
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const customOrganisationFilter = (row:any, columnId: any, filterValue:any) => {
      const { organisation_name, organisation_id } = row.original;
      const searchValue = filterValue.toLowerCase();
      return (
        organisation_name.toLowerCase().includes(searchValue) || 
        organisation_id.toString().includes(searchValue)
      );
    };

    const filterFns = {
      ...MRT_FilterFns,
      customOrganisationFilter,
    };


    const columns: MRT_ColumnDef<any>[] = [
        {
            accessorKey: 'id',
            header: 'ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Housing<br />ID
            </div>, 
            filterFn: (row, id, filterValue) => {
              const idValue = row.getValue(id);          
              // Ensure both values are numbers before comparison
              return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
              },   
        },  
        {
          accessorKey: 'name',
          header: 'Name',
          Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          Name<br />&nbsp;
          </div>,   
        },  
        {
          accessorKey: 'description',
          header: 'Sample ID',
          Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          Description<br />&nbsp;
          </div>,   
        },  
        {
            accessorFn: (row) => dayjs(row.date_created).format('YYYY-MM-DD HH:mm:ss'),
            header: 'Date Created',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Date<br />Created
            </div>, 
            Cell: ({ cell }) => (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {cell.getValue()?.toString().split(' ').join('\n')}
                </div>
              ),
        },
        {
          accessorFn: (row) => dayjs(row.date_updated).format('YYYY-MM-DD HH:mm:ss'), // Change to updated
          header: 'Date Updated',
          Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          Updated<br />Date
          </div>, 
          Cell: ({ cell }) => (
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {cell.getValue()?.toString().split(' ').join('\n')}
              </div>
            ),
      },
      {
        accessorKey: 'organisation_name', // Base accessor
        header: "organisation",
        Header: () => (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Organisation<br />&nbsp;
          </div>
        ),
        Cell: ({ row }) => (
          <div>
            {row.original.organisation_id} {row.original.organisation_name}
          </div>        
        ),
        enableColumnFilter: true,
        filterFn: 'customOrganisationFilter',
      },    
      {
        accessorKey: 'is_active',
        header: 'Status',
        Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
        Status<br />&nbsp;
        </div>, 
        Cell: ({ cell }) => (
            <div style={{display: "flex", justifyContent: "center"}}>
              {cell.getValue()?.toString() == "true" ? <Typography fontSize="smaller" sx={{ paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography>: <Typography fontSize="smaller" sx={{ paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.inactive}`, borderRadius: 2, color: colorConfigs.buttons.inactive }} >Inactive</Typography>}
            </div>
          ),
        filterVariant: 'checkbox',

    },
    ]


    // Define the type for the keys for handling persistance of table settings in session storage
    type ResultSessionStorageKeyType = keyof ResultSessionStorageModel;

    // Generic handler function for persisting table parameters
    const handleParameterChange = <T,>(
        key: ResultSessionStorageKeyType,
        currentValue: T,
        setValueFunction: (value: T) => void,
        newValue: T | ((prevValue: T) => T)
        ) => {
        const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
        ResultTableParameterStorageService.set(key, updatedValue);
        setValueFunction(updatedValue);
        };

    // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
    const setSortingFunction = (newSortingValue: any) =>
        handleParameterChange(ResultSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
    const setPaginationChangeFunction = (newPaginationValue: any) =>
        handleParameterChange(ResultSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
    const setColumnVisibilityFunction = (newColumnVisibilityValue: any) =>
        handleParameterChange(ResultSessionStorageKey.columnVisibility, columnVisibility, setColumnVisibility, newColumnVisibilityValue);
    const setFilterChangeFunction = (newFilterChangeValue: any) =>
        handleParameterChange(ResultSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
    const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
        handleParameterChange(ResultSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
    const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
        handleParameterChange(ResultSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);



    return <MaterialReactTable
                columns={columns}
                data={rows}
                filterFns={filterFns}
                state={{
                isLoading,
                sorting,
                columnVisibility,
                columnFilters,
                pagination,
                globalFilter,
                showGlobalFilter
                }}
                onSortingChange={setSortingFunction}
                onColumnVisibilityChange={setColumnVisibilityFunction}
                onColumnFiltersChange={setFilterChangeFunction}
                onPaginationChange={setPaginationChangeFunction}
                onGlobalFilterChange={setGlobalFilterChangeFunction}
                onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
                defaultColumn={{    
                minSize: 10,
                maxSize: 160,
                size: 10,
                }}
                positionActionsColumn="last"
                enableRowSelection
                selectAllMode="all"
                // enableRowActions
                initialState={{
                sorting,
                columnVisibility,
                columnFilters,
                pagination,
                globalFilter,
                density: 'compact',
                columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions']},
                }}

            // TABLE STYLING IS IN THE THEME
            displayColumnDefOptions={{
                'mrt-row-actions': {
                header: "Actions", // Renames Actions Column
                },
            }}

            muiTopToolbarProps={({ table }) => ({
                sx: (theme: any) => ({
                  ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
                }),
              })}

            muiTableHeadProps={{
                sx: (theme: any) => theme.components?.MuiTableHead?.styleOverrides?.root || {},
              }}

            muiTableHeadCellProps={({ column }) => ({
                sx: (theme: any) => ({
                  ...(theme.components?.MuiTableCell?.styleOverrides?.head || {}),
                  ...(column.id === 'mrt-row-select' && {
                    maxWidth: '50px',
                    width: '50px',
                  }),
                }),
              })}

            muiFilterTextFieldProps={{
                sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
              }}

            muiTableBodyRowProps={({ row }) => ({
                onClick: (event) => {
                    console.log(row.original);
                    setHousing(row.original);
                    setPopup('Edit');
                },
                sx: (theme: any) => ({
                    ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
                    backgroundColor:
                    row.index % 2 === 0
                        ? colorConfigs.tables.firstRow
                        : colorConfigs.tables.secondRow,
                }),
                })}

            muiTableBodyProps={{
                sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
                }}
            
                muiPaginationProps={{
                    rowsPerPageOptions: [250, 500, 1000],
                    sx: {
                        padding: 0,  
                        margin: 0,   
                        '& .MuiTablePagination-root': {
                        padding: 0,  
                        margin: 0,   
                        },
                        '& .MuiToolbar-root': {
                        padding: 0,
                        margin: 0,
                        minHeight: '0px',
                        },
                        '& .MuiTablePagination-selectLabel': {
                        margin: 0,
                        },
                        '& .MuiTablePagination-displayedRows': {
                        margin: 0,
                        },
                        '& .MuiTablePagination-actions': {
                        margin: 0,
                        },
                    },
                    }}

            muiTableBodyCellProps={({ cell }) => ({
                // TABLE BODY CELLS
                sx: {
                },
            })}

            muiBottomToolbarProps={{
                // BOTTOM TOOLBAR (Pagination)
                sx: {
                    height: '41px',
                    maxHeight: '41px',
                    minHeight: '41px',
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 1,
                    backgroundColor: '#fff',
                    boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)', 
                    margin: 0,
                    padding: 0,
                  '& .MuiToolbar-root': {
                    padding: 0,
                    margin: 0,
                    paddingBottom: 0,
                    paddingTop: 0,
                  },
                  '& .MuiBox-root': {
                    p: 0,
                  },
                  '& .MuiIconButton-root': {
                    color: colorConfigs.tables.headBg,
                  },
                  '& .MuiTablePagination-root': {
                    margin: 0,
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                    color: colorConfigs.buttons.bg,
                  },
                  '& .MuiTablePagination-selectLabel': {
                    color: colorConfigs.tables.headBg,
                    padding: 0,
                    margin: 0,
                  },
                  '& .MuiTablePagination-displayedRows': {
                    color: colorConfigs.buttons.bg,
                    padding: 0,
                    margin: 0,
                  },
                  '& .MuiTablePagination-actions': {
                    color: colorConfigs.tables.headBg,
                    padding: 0,
                    margin: 0,
                  },
                },
              }}


            renderTopToolbarCustomActions={({ table }) => {

                const handleArchiveResults = () => {
                    table.getSelectedRowModel().flatRows.map(row => {
                        //Add archive logic
                    });
                };


                const handleExportRows = (rows: MRT_Row<any>[]) => {
                  var excel_rows = []
                  var now = dayjs().format('YYYY-MM-DD HH:mm:ss')
                  var filename = now + "_HousingList"
          
                  for (var x = 0; x < rows.length; x++) {
                    const formattedDateCreated = dayjs(rows[x].original.date_created).format('YYYY-MM-DD HH:mm:ss')
                    const formattedDateUpdated = dayjs(rows[x].original.date_created).format('YYYY-MM-DD HH:mm:ss') // Change to updated
                    
                    excel_rows[x] = {
                      "ID": rows[x].original.id,
                      "Name": rows[x].original.name,
                      "Description": rows[x].original.description,
                      "Date Created": formattedDateCreated,
                      "Date Updated": formattedDateUpdated,
                      "Organisation ID": rows[x].original.organisation_id,
                      "Organisation Name": rows[x].original.organisation_name,
                      "Status": rows[x].original.is_active ? "ACTIVE" : "INACTIVE",
                    }
                  }
          
                  tsXLXS().exportAsExcelFile(excel_rows).saveAsExcelFile(filename);
          
                };

                return (
                    <Box style={{ display: 'flex', gap: '0.3rem', height: "40px" }}>
                      <Button
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        disableRipple
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        sx={{ minHeight: "100%",  minWidth: "7.5em", background: colorConfigs.buttons.bg, color: "#fff", textTransform: 'none', fontWeight: "bold" }}
                      >
                        Bulk Actions
                      </Button>  
                      <Button
                      fullWidth
                      onClick={() => setPopup("Create")}
                      variant="contained"
                      id="demo-customized-button3"
                      disableElevation
                      endIcon={<CabinOutlinedIcon />}
                      sx={{ 
                        background: colorConfigs.buttons.bg, 
                        color: 'white', 
                        minWidth: "19.85em",
                        width: "fit-content",
                        minHeight: "100%",
                        textTransform: 'none', 
                        transition: 'transform 0.3s ease', 
                        fontWeight: 'bold', 
                      }}                
                    >
                      Create Housing
                    </Button> 
                      <Button 
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={() => handleRefreshButton()}
                        sx={{ minHeight: "100%", textTransform: 'none', background: colorConfigs.buttons.bg, color: "#fff", fontWeight: "bold",}}
                        >
                        <RefreshIcon sx={{ p: 0, m: 0 }} />
                    </Button>
                      <Button
                        id="demo-customized-button1"
                        variant="contained"
                        disableElevation
                        onClick={() => handleRemoveAllFilters()}
                        endIcon={<FilterAltOffOutlinedIcon />}
                        sx={{ 
                          minWidth: "5em",
                          minHeight: "100%",
                          background: colorConfigs.buttons.bg, 
                          color: "#fff", 
                          textTransform: 'none', 
                          fontWeight: "bold", 
                        }}
                      >
                        Clear Filters
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem disabled={
                          !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
                          <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <FileDownloadOutlinedIcon />
                            <Typography>Export to Excel</Typography>
                          </Box>    
                        </MenuItem>
                      </StyledMenu>
                      </Box>
                );
            }}
        />
}
 
export default HousingTable;

