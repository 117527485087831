import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { blue, red, green, purple, yellow} from '@mui/material/colors'
import colorConfigs from './configs/colorConfigs';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      menu: 1150,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: "#6987C9"
    },
    secondary: {
      main: "#B55F5D",
      muteGreen: "#69C987",
      muteYellow: "#C9A769",
      jet: '#2D2E2E',
    },
    background: {
      default: '#f9f9f9',
      gradient: 'linear-gradient(45deg, #E4261F 50%, #BB1B16 80%)',
      gradientCompany: 'linear-gradient(90deg, #E4261F 50%, #BB1B16 80%)',
      grey: '#919292', // Battleship Grey for backgrounds and contrasting
      silver: '#C3C4C4', // Silver for backgrounds and contrasting
      white_smoke: '#F5F5F5', // White smoke for backgrounds of components or contrasting
      off_white: '#EBEBEB', // off white for global background, less glaring that full white
      platinum: '#E0E0E1', // Plantinum, lighter silver
      whiteHeader: 'rgba(255, 255, 255, 0.3)', // White with 0.3 opacity
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'background 1s ease-in-out',
        },
        contained: {
          background: "linear-gradient(45deg, #5A77B7, #7B97D4)",
          '&:hover': {
            background: "linear-gradient(45deg, #4A66A1, #6A84BC)", // Darker gradient for contained variant
          },
        },
        outlined: {
          // Keep default outlined button styles, no background change
          background: 'none',  // Ensure background remains transparent
          '&:hover': {
            background: 'none',  // No background change on hover
          },
        },
      },
    }, 
    MuiAppBar: {
      styleOverrides: {
        root: {
          // backgroundColor: "linear-gradient(45deg, #5A77B7, #7B97D4)", // Use the whiteHeader background color
          boxShadow: 'unset',
        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiOutlinedInput-root': {
    //         '& fieldset': {
    //           borderColor: '#2D2E2E', // Default border color
    //           backgroundColor: "rgba(0, 0, 0, 0.05)"
    //         },
    //         '&:hover fieldset': {
    //           borderColor: '#2D2E2E', // Border color on hover
    //         },
    //         '&.Mui-focused fieldset': {
    //           borderColor: '#2D2E2E', // Border color when focused
    //         },
    //         '&.Mui-error fieldset': {
    //           borderColor: '#2D2E2E', // Border color when there is an error
    //         },
    //       },
    //       '& .MuiFormLabel-root': {
    //         '&.Mui-focused': {
    //           color: '#2D2E2E', // Label color when focused
    //         },
    //         '&.Mui-error': {
    //           color: '#2D2E2E', // Label color when there is an error
    //         },
    //       },
    //     },
    //   },
    // },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#2D2E2E', // Default border color
              backgroundColor: "rgba(0, 0, 0, 0.05)"
            },
            '&:hover fieldset': {
              borderColor: '#2D2E2E', // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#2D2E2E', // Border color when focused
            },
            '&.Mui-error fieldset': {
              borderColor: '#2D2E2E', // Border color when there is an error
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#2D2E2E', // Label color when focused
          },
          '&.Mui-error': {
            color: '#2D2E2E', // Label color when there is an error
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            '&.Mui-focused': {
              color: '#2D2E2E', // Label color when focused
            },
            '&.Mui-error': {
              color: '#2D2E2E', // Label color when there is an error
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#2D2E2E', // Default border color
            backgroundColor: "rgba(0, 0, 0, 0.05)"
          },
          '&:hover fieldset': {
            borderColor: '#2D2E2E', // Border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#2D2E2E', // Border color when focused
          },
          '&.Mui-error fieldset': {
            borderColor: '#2D2E2E', // Border color when there is an error
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: "#C96969", // Error message color
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          boxShadow: 0,
          border: 0, 
          background: '#fff',   
          minHeight: 'calc(100vh - 200px)',
          maxHeight: 'calc(100vh - 200px)',
          flex: 1,
          overflowY: 'auto',
          paddingBottom: '300px',
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none', // Internet Explorer 10+
          scrollbarWidth: 'none', // Firefox
        },
      },
    },
    MuiCustomToolbar: {
      styleOverrides: {
        root: {
          position: 'sticky',
          background: '#f9f9f9',           
          boxShadow: 0,
          border: 0,
          top: 0,
          zIndex: 1,
          overflowY: 'hidden',
          p: 0.7,
          height: "fit-contents",
          maxHeight: "fit-contents",
          minHeight: "fit-contents",
          m: 0,
          color: colorConfigs.sidebar.color,
          '& .MuiToolbar-root': {
             width: "50%",
             padding: 0,
             margin: 0,
             paddingBottom: 0,
             paddingTop: 0,
          },
          '& .MuiBox-root': {
            p:0
          },
          '& .MuiIconButton-root': {
            color: colorConfigs.tables.headBg,
          },
          '& .MuiTextField-root': {
            p:0,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: colorConfigs.tables.headBg,
              },
              '&:hover fieldset': {
                borderColor: colorConfigs.tables.headBg,
              },
              '&.Mui-focused fieldset': {
                borderColor: colorConfigs.tables.headBg,
              },
            },
            '& .MuiInputBase-input': {
              color: colorConfigs.tables.headBg,
            },
            '& .MuiInputAdornment-root .MuiSvgIcon-root': {
              color: colorConfigs.tables.headBg,
            },
          },
          '& .MuiTablePagination-root': {
            margin: 0,
            paddingTop: 0.5,
            paddingBottom: 0.5,
            color: colorConfigs.buttons.bg,
          },
          '& .MuiTablePagination-selectLabel': {
            color: colorConfigs.tables.headBg,
          },
          '& .MuiTablePagination-displayedRows': {
            color: colorConfigs.buttons.bg,
          },
          '& .MuiTablePagination-actions': {
            color: colorConfigs.tables.headBg,
          },
          '& .MuiInputAdornment-root .MuiSvgIcon-root': {
            color: colorConfigs.tables.headBg,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          position: 'sticky',
          top: 0,
          zIndex: 1,
          overflowY: 'hidden',
          color: '#fff',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          border: '1px solid rgba(255, 255, 255, 0.3)',
          backgroundColor: colorConfigs.tables.headBg,
          opacity: 0.95,
          color: '#fff',
          paddingBottom: 0.2,
          paddingTop: 0.2,
          '& .MuiIconButton-root': {
            color: '#fff',
          },
          '& .MuiSvgIcon-root': {
            color: '#fff',
            padding: 0,
            margin: 0,
          },
          '& .MuiTableSortLabel-root': {
            color: '#fff',
            margin: 0,
            marginLeft: 1,
            marginRight: -0.5,
            '&.Mui-active': {
              color: '#fff',
            },
            '& .MuiTableSortLabel-icon': {
              color: '#fff !important',
              marginLeft: 'auto',
            },
          },
          '& .MuiTableSortLabel-iconDirectionAsc': {
            color: '#fff !important',
            p:0,
            m:0,
            ml:3
          },
          '& .MuiTableSortLabel-iconDirectionDesc': {
            color: '#fff !important',
            p:0,
            m:0,
            ml:3
          },
        },
      },
    },
    // Add Filter Text Field Styles
    MuiFilterTextField: {
      styleOverrides: {
        root: {
          padding: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          color: '#fff',
          '& .MuiIconButton-root': {
            color: 'rgba(255, 255, 255, 0.5)',
          },
          '& .MuiInputBase-input': {
            color: '#fff',
            p: 0.5,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'none',
          },
          '& .MuiInput-underline:before': {
            borderColor: 'none',
          },
          '&:hover .MuiInput-underline:before': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
          },
          '& .MuiInput-underline:after': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
          },
        },
      },
    },
    // Add Table Body Row Styles
    MuiTableBodyRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          paddingBottom: 0,
          paddingTop: 0,
          backgroundColor: (row) =>
            row.index % 2 === 0
              ? colorConfigs.tables.firstRow
              : colorConfigs.tables.secondRow,
        },
      },
    },
    // Add Table Body Styles
    MuiTableBody: {
      styleOverrides: {
        root: {
          maxHeight: '100%',
          overflowY: 'auto',
        },
      },
    },
    // Add Pagination Toolbar Styles
    MuiPaginationToolbar: {
      styleOverrides: {
        root: {
          padding: 0,  // Remove padding
          margin: 0,   // Remove margin
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colorConfigs.tables.headBg,
          color: '#fff',
          fontSize: '0.875rem',
          padding: '8px 12px',
        },
        arrow: {
          color: colorConfigs.tables.headBg, // Match arrow color with background
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
