import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Box, CircularProgress } from "@mui/material";
import {
  getHomographyInverse,
  getTransformedPoint,
  Matrix3x3,
  Point,
} from "../../../utils/math";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import api from "../../../redux/features/auth/axiosAuthHelper";

export interface ImageProfile {
  profile: number[];
  sample_rows: number;
  sample_cols: number;
}

type ImageProfileSetter = React.Dispatch<
  React.SetStateAction<ImageProfile | undefined>
>;

type StripCornersSetter = React.Dispatch<React.SetStateAction<Point[]>>;

interface RegionSelectorCardProps {
  imageUrl: string;
  homography: number[];
  setImageProfile: ImageProfileSetter;
  setStripCorners: StripCornersSetter;
  setOriginalStripCorners: StripCornersSetter;
  originalStripCorners: Point[];
}

const RegionSelectorCard: React.FC<RegionSelectorCardProps> = ({
  imageUrl,
  homography,
  setImageProfile,
  setStripCorners,
  setOriginalStripCorners,
  originalStripCorners,
}) => {
  const authData = useSelector((state: RootState) => state.auth);

  const [coordinates, setCoordinates] = useState<Point[]>([]);
  const [modelCoordinates, setModelCoordinates] = useState<Point[]>([]);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [inverseHomography, setInverseHomography] = useState<Matrix3x3>();
  const maxPoints = 4;

  useEffect(() => {
    setInverseHomography(getHomographyInverse(homography));
  }, [homography]);

  // Update model coordinates when coordinates change
  useEffect(() => {
    if (inverseHomography && coordinates.length > 0) {
      if (coordinates.length < maxPoints) {
        // Transform only the last coordinate
        const newPoint: Point = coordinates[coordinates.length - 1];
        const newModelPoint: Point = getTransformedPoint(
          newPoint,
          inverseHomography
        );

        setModelCoordinates((prevCoordinates) => [
          ...prevCoordinates,
          [
            parseInt(newModelPoint[0].toString(), 10), // truncate, instead of round
            parseInt(newModelPoint[1].toString(), 10),
          ],
        ]);
      }
    } else {
      setModelCoordinates([]);
    }
  }, [coordinates, inverseHomography]);

  const canculate4thCoordinates = useCallback((coords: Point[]): Point => {
    const xOffset = coords[1][0] - coords[0][0];
    const yOffset = coords[2][1] - coords[0][1];
    const x = coords[2][0] + xOffset;
    const y = coords[1][1] + yOffset;
    return [x, y];
  }, []);

  // calculate 4th model coordinates.
  useEffect(() => {
    if (modelCoordinates.length > 0) {
      console.log(
        "New model point:",
        modelCoordinates[modelCoordinates.length - 1]
      );

      if (modelCoordinates.length === 3) {
        const newPoint: Point = canculate4thCoordinates(modelCoordinates);
        setModelCoordinates((prevCoordinates) => [
          ...prevCoordinates,
          newPoint,
        ]);
      } else if (modelCoordinates.length === maxPoints) {
        fetchProfile();
      }
    }
  }, [modelCoordinates, canculate4thCoordinates]);

  // calculate 4th coordinates
  useEffect(() => {
    if (coordinates.length > 0) {
      console.log("New point:", coordinates[coordinates.length - 1]);
      if (coordinates.length === 3) {
        const newPoint: Point = canculate4thCoordinates(coordinates);
        setCoordinates((prevCoordinates) => [...prevCoordinates, newPoint]);
      }
    }
  }, [coordinates, canculate4thCoordinates]);

  useEffect(() => {
    if (originalStripCorners.length === maxPoints) {
      setCoordinates(originalStripCorners);
    }
  }, [originalStripCorners]);

  // Handle click to get coordinates in data units and mark the point
  const handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
    if (imageRef.current && coordinates.length < maxPoints) {
      const rect = imageRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setCoordinates((prevCoords) => [...prevCoords, [x, y]]);
    }
  };

  const fetchProfile = async () => {
    setLoading(true);
    setStripCorners(modelCoordinates);
    setOriginalStripCorners(coordinates);
    await getImageProfile();
    setLoading(false);
  };

  // Clear all markers
  const clearMarkers = () => {
    setCoordinates([]);
    setModelCoordinates([]);
  };

  const getImageProfile = async () => {
    const data = {
      image_url: imageUrl,
      strip_corners: modelCoordinates,
      homography,
    };
    try {
      const response = await api.post("calculate-image-profile/", data, {
        headers: {
          Authorization: `Bearer ${authData.access}`,
        },
      });
      setImageProfile(response.data);
    } catch (err) {
      console.error("Failed to fetch imageProfile", err);
    }
  };

  return (
    <>
      {imageUrl && (
        <Box position="relative" display="inline-block">
          <img
            src={imageUrl}
            alt="Select region"
            ref={imageRef}
            onClick={handleImageClick}
            style={{ maxWidth: "100%", cursor: "crosshair" }}
          />
          {coordinates.map((marker, index) => (
            <Box
              key={index}
              sx={{
                position: "absolute",
                left: `${marker[0]}px`,
                top: `${marker[1]}px`,
                width: "5px",
                height: "5px",
                bgcolor: "red",
                borderRadius: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ))}
          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)", // Center align
              }}
            >
              <CircularProgress
                sx={{
                  color: "red",
                }}
                thickness={6}
                size={100}
              />
            </Box>
          )}
        </Box>
      )}

      {imageUrl && (
        <Button
          variant="contained"
          color="secondary"
          onClick={clearMarkers}
          disabled={coordinates.length === 0}
        >
          Reset Corners
        </Button>
      )}
    </>
  );
};

export default RegionSelectorCard;
