import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { StripRegion } from "../CustomHooks";

type RegionSetter = React.Dispatch<React.SetStateAction<Region[]>>;
type StripRegionsSetter = React.Dispatch<React.SetStateAction<StripRegion[]>>;

interface ProfileChartProps {
  profile: number[] | undefined;
  tickAmountX?: number;
  tickAmountY?: number;
  resetTrigger?: boolean;
  setStripRegions: StripRegionsSetter;
  initialRegions: StripRegion[];
  position: number;
}

type RegionType = "BASELINE" | "CONTROL" | "TEST";

type Region = {
  x: number;
  x2: number | undefined;
};

type RegionConfig = {
  setter: RegionSetter;
  state: Region[];
  color: string;
  label: string;
};

const ProfileChart: React.FC<ProfileChartProps> = ({
  profile,
  tickAmountX = 10,
  tickAmountY = 6,
  resetTrigger = false,
  setStripRegions,
  initialRegions,
  position,
}) => {
  const [baselineRegions, setBaselineRegions] = useState<Region[]>([]);
  const [testRegions, setTestRegions] = useState<Region[]>([]);
  const [controlRegions, setControlRegions] = useState<Region[]>([]);
  const chartId = `interactive-zone-chart-${position}`;

  const regionConfig: Record<RegionType, RegionConfig> = useMemo(
    () => ({
      TEST: {
        state: testRegions,
        setter: setTestRegions,
        color: "#FF5733", // Red-Orange
        label: "Test",
      },
      BASELINE: {
        state: baselineRegions,
        setter: setBaselineRegions,
        color: "#3498DB", // Blue
        label: "Baseline",
      },
      CONTROL: {
        state: controlRegions,
        setter: setControlRegions,
        color: "#27AE60", // Green
        label: "Control",
      },
    }),
    [testRegions, baselineRegions, controlRegions]
  );

  const [selectedRegionType, setSelectedRegionType] =
    useState<RegionType>("BASELINE");

  const [addMode, setAddMode] = useState(true); // Toggle between Add and Remove mode

  const handleRegionTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedRegionType(event.target.value as RegionType);
  };

  const handleRegionsReset = () => {
    setBaselineRegions([]);
    setTestRegions([]);
    setControlRegions([]);
  };

  useEffect(() => {
    if (resetTrigger) {
      handleRegionsReset();
    }
  }, [resetTrigger]);

  useEffect(() => {
    setTestRegions(
      initialRegions
        .filter((region) => region.category === "TEST")
        .map((item) => ({ x: item.lower_bound, x2: item.upper_bound }))
    );
    setBaselineRegions(
      initialRegions
        .filter((region) => region.category === "BASELINE")
        .map((item) => ({ x: item.lower_bound, x2: item.upper_bound }))
    );
    setControlRegions(
      initialRegions
        .filter((region) => region.category === "CONTROL")
        .map((item) => ({ x: item.lower_bound, x2: item.upper_bound }))
    );
  }, [initialRegions]);

  useEffect(() => {
    const regionsWithType: [Region[], string][] = [
      [testRegions, "TEST"],
      [baselineRegions, "BASELINE"],
      [controlRegions, "CONTROL"],
    ];
    const filteredRegions = regionsWithType.flatMap(([regions, category]) =>
      regions
        .filter((region) => region.x2 !== undefined) // Filter out regions without x2
        .map((region) => ({
          lower_bound: region.x,
          upper_bound: region.x2!,
          category,
        }))
    );
    setStripRegions(filteredRegions);
  }, [testRegions, baselineRegions, controlRegions]);

  const plotRegions = useCallback(
    (regions: Region[], color: string, label: string) => {
      return regions.map(({ x, x2 }, index) => {
        if (x2 === undefined) {
          return {
            x: x + 1,
            borderColor: color,
            strokeDashArray: 4,
            label: {
              text: `Start ${label}`,
              style: { color },
            },
          };
        } else {
          return {
            x: x + 1,
            x2: x2 + 1,
            borderColor: color,
            fillColor: color,
            opacity: 0.2,
            label: {
              text: `${label}`,
              style: { color },
            },
          };
        }
      });
    },
    []
  );

  useEffect(() => {
    const chart = ApexCharts.getChartByID(chartId);
    if (chart) {
      const xaxis1 = plotRegions(
        regionConfig["TEST"].state,
        regionConfig["TEST"].color,
        regionConfig["TEST"].label
      );
      const xaxis2 = plotRegions(
        regionConfig["BASELINE"].state,
        regionConfig["BASELINE"].color,
        regionConfig["BASELINE"].label
      );
      const xaxis3 = plotRegions(
        regionConfig["CONTROL"].state,
        regionConfig["CONTROL"].color,
        regionConfig["CONTROL"].label
      );
      chart.updateOptions({
        annotations: {
          xaxis: [...xaxis1, ...xaxis2, ...xaxis3],
        },
      });
    }
  }, [plotRegions, regionConfig, chartId]);

  const handleMarkerClick = (newX: number) => {
    if (!addMode) {
      const setters = [setTestRegions, setBaselineRegions, setControlRegions];
      setters.forEach((setter, index) => {
        setter((prevRegions) =>
          prevRegions.filter(
            (region) =>
              region.x > newX || region.x2 === undefined || newX > region.x2
          )
        );
      });
      return;
    }
    const setRegions = regionConfig[selectedRegionType].setter;
    setRegions((prevRegions) => {
      const lastRegion = prevRegions[prevRegions.length - 1];
      if (!lastRegion || lastRegion.x2 !== undefined) {
        return [...prevRegions, { x: newX, x2: undefined }];
      } else if (lastRegion.x === newX) {
        return prevRegions; // Ignore if we clicked the same point
      } else {
        return [
          ...prevRegions.slice(0, -1),
          lastRegion.x < newX
            ? { x: lastRegion.x, x2: newX }
            : { x: newX, x2: lastRegion.x },
        ];
      }
    });
  };

  const chartOptions: ApexOptions = {
    chart: {
      id: chartId,
      type: "line",
      zoom: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
      events: {
        markerClick: (event, chartContext, opts) => {
          handleMarkerClick(opts.dataPointIndex);
        },
      },
    },
    xaxis: {
      title: { text: "Index" },
      tickAmount: tickAmountX,
    },
    yaxis: {
      title: { text: "Intensity" },
      tickAmount: tickAmountY,
      decimalsInFloat: 0,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
  };

  const chartSeries = [
    {
      name: "Image Profile",
      data: profile ?? [],
    },
  ];

  return (
    <>
      {profile && (
        <Box width="100%" mt={4}>
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="line"
            height={350}
          />

          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FormControlLabel
              control={
                <Switch
                  checked={addMode}
                  onChange={() => setAddMode(!addMode)}
                />
              }
              label={addMode ? "Add Mode" : "Remove Mode"}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleRegionsReset}
              sx={{ ml: 2 }}
            >
              Clear Regions
            </Button>
          </Box>

          {addMode && (
            <>
              <Box
                mt={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#6987C9",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Select Region Type
                </Typography>
              </Box>
              <Box
                mt={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <RadioGroup
                  row
                  value={selectedRegionType}
                  onChange={handleRegionTypeChange}
                >
                  <FormControlLabel
                    value="TEST"
                    control={<Radio />}
                    label="Test"
                  />
                  <FormControlLabel
                    value="BASELINE"
                    control={<Radio />}
                    label="Baseline"
                  />
                  <FormControlLabel
                    value="CONTROL"
                    control={<Radio />}
                    label="Control"
                  />
                </RadioGroup>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default ProfileChart;
