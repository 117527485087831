const assets = {
    images: {
        logoinitial: require("./images/logoinitial.png"),
        profile: require("./images/profileicon.png"),
        emailicon: require("../assets/images/EmailIcon.png"),
        greenCross: require("../assets/images/GreenCross.svg").default,
        blueCross: require("../assets/images/BlueCross.svg").default,
        logo: require("../assets/images/logo.png"),
        novarumLogo: require("../assets/images/novarumLogo.png"),
        normalresult: require("./images/normal_result.png"),
        moderateresult: require("./images/moderate_result.png"),
        highresult: require("./images/high_result.png"),
        invalidresult: require("./images/invalid_result.png"),
        apple: require("./images/silver_apple_logo.png"),
        android: require("./images/android_logo.png"),
        reprocessed: require("./images/reprocessed_logo.png"),
    },
    actions: {},
    fonts: {}
}

export default assets;