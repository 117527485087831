import React, { useState, useEffect, SyntheticEvent } from "react";
import {
  Snackbar,
  Alert as MuiAlert,
  SnackbarCloseReason,
} from "@mui/material";

type SeverityType = "error" | "success" | "info" | "warning";

type ErrorQueueSetter = React.Dispatch<React.SetStateAction<string[]>>;
type SuccessMessageSetter = React.Dispatch<React.SetStateAction<string | null>>;

interface MethodCardProps {
  errorQueue: string[];
  successMessage: string | null;
  setErrorQueue: ErrorQueueSetter;
  setSuccessMessage: SuccessMessageSetter;
}

export const parseErrors = (errorData: any): string[] => {
  const errors: string[] = [];

  const traverse = (data: any, path = "") => {
    if (Array.isArray(data)) {
      // If it's an array, traverse its elements
      data.forEach((item, index) => traverse(item, `${path}[${index}]`));
    } else if (typeof data === "object" && data !== null) {
      // If it's an object, traverse its keys
      Object.entries(data).forEach(([key, value]) =>
        traverse(value, path ? `${path}.${key}` : key)
      );
    } else {
      // If it's a value, add it to the errors array
      errors.push(data);
    }
  };

  traverse(errorData);
  return errors;
};

const NotificationSnackbarCard: React.FC<MethodCardProps> = ({
  errorQueue,
  successMessage,
  setErrorQueue,
  setSuccessMessage,
}) => {
  const [currentMessage, setCurrentMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<SeverityType>("info");

  useEffect(() => {
    if (errorQueue.length && !currentMessage) {
      // Show the next error from the queue
      setCurrentMessage(errorQueue[0]);
      setSeverity("error");
      setErrorQueue((prev) => prev.slice(1));
    } else if (!currentMessage && successMessage) {
      // Show the success message if no errors are left
      setCurrentMessage(successMessage);
      setSeverity("success");
      setSuccessMessage(null); // Reset success after displaying
    }
  }, [errorQueue, currentMessage, successMessage]);

  const handleSnackbarClose = (
    event: Event | SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setCurrentMessage(null);
    setSeverity("info");
  };
  const handleAlertClose = (event: SyntheticEvent<Element, Event>) => {
    setCurrentMessage(null);
    setSeverity("info");
  };

  return (
    <Snackbar
      open={!!currentMessage}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <MuiAlert
        onClose={handleAlertClose}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {currentMessage}
      </MuiAlert>
    </Snackbar>
  );
};

export default NotificationSnackbarCard;
