import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";


const TestsGraph = () => {

    const series = [{
        name: 'N/A',
        data: [0, 0, 0, 34, 56, 62, 59, 46, 24, 0, 0, 0]
      },{
        name: 'Negative',
        data: [44, 78, 101, 105, 112, 116, 0, 0, 0, 0, 0, 0]
      }, {
        name: 'Positive',
        data: [0, 0, 0, 0, 0, 0, 116, 112, 106, 101, 75, 45]
      }
    ]   
    

    const options: ApexOptions = {
          chart: {
            type: 'bar',
            height: 400,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '100%',
              borderRadius: 5,
              borderRadiusApplication: 'end',
            },
          },
          colors: [
             '#964B00','#FF0000','#008000'
          ],
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 0,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['-2.0', '-1.5', '-1.0', '-0.5', '0', '0.5', '1.0', '1.5', '2.0', '2.5', '3.0', '3.5'],
          },
          yaxis: {
            title: {
              text: 'Intensity'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val:any) {
                return val
              }
            }
          }
        };

    

    return (
      <div>
        <div id="chart">
            <ReactApexChart 
            type="bar"
            options={options}
            series={series}
            height={400}
             />
          </div>
        <div id="html-dist"></div>
      </div>
    );
  }

export default TestsGraph

// const options: ApexOptions = {
//     chart: {
//       height: 350,
//       type: 'line',
//       zoom: {
//         enabled: true
//       }
//     },
//   };
  
//   <ReactApexChart
//     options={options}
//     series={series}
//     type="line"
//     height={350}
//   />