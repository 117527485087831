import { Navigate, useNavigate } from "react-router-dom";
import { Box, Button, Card, Collapse, Divider, Grid, Grid2, linearProgressClasses, Link, Paper, Tab, Table, TableCell, tableCellClasses, TableRow, Tabs, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import RadialGraph from "../../components/graphs/multiradialgraph";
import LineGraph from "../../components/graphs/linegraph";
import BarGraph from "../../components/graphs/bargraph";
import LastFiveRList from '../../components/common/tables/LastFiveRList';
import LastFiveFList from '../../components/common/tables/LastFiveFList';
import { last5columns, organisationColumns } from '../../components/common/tables/Columns';
import api from "../../redux/features/auth/axiosAuthHelper";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { refreshAccessToken } from "../../redux/features/auth/authSlice";
import dayjs from 'dayjs';
import colorConfigs from "../../configs/colorConfigs";
import ResultDetailGraph from "../../components/common/graphs/ResultDetailGraph";
import SubscriptionsTable from "../../components/common/tables/SubscriptionsTable";
import { LatestDCMSessionStorageKey, LatestDCMTableParameterStorageService } from "../../services/sessionStorage";
import LastFiveResultsTable from "../../components/common/tables/LastFiveResultsTable";
import RecentDCMResultsTable from "../../components/common/tables/RecentDCMResultsTable";
import { Field, FormikProvider, useFormik } from "formik";
import fontConfigs from "../../configs/fontConfigs";
import { ResultDetailTableDashboardField, ResultDetailTableField } from "../../components/common/fields/fields";
import dummyresultdata from "../../components/common/graphs/resultdata";
import * as Yup from "yup";
import TestsGraph from "../../components/common/graphs/TestInsightsGraph";
import UsageGraph from "../../components/common/graphs/UsageGraph";
import ResultSourceRadarGraph from "../../components/common/graphs/ResultSourceRadarGraph";
import ResultSourceRadialGraph from "../../components/common/graphs/ResultSourceRadialGraph";



type Props = {}

interface LatestDCM {
  id: string;
  organisation_id: number | null;
  user_id: number | null;
  timestamp: string;
  sample_id: string;
  method_id: number | null;
  housing_id: number | null;
  test_configuration: {
    collection_mode: boolean | null;
  }
}

interface LineData {
  score: number | string;
  peak_position: number | string;
  tc_ratio: number | string;
  t_color?: string; // Color for each T-peak line
}

interface GraphData {
  test_lines: LineData[];        // Data for test lines with T-peaks
  cscore: number | string;       // Score for the C-line
  cpeak: number | string;        // Peak position for the C-line
  c_color?: string;               // Color for the C-peak annotation
  profile: any[];                // Mean profile data
  baseline: any[];               // Mean baseline data
  baseline_color: string;        // Color for the mean baseline series
  profile_color: string;         // Color for the mean profile series
  meanProfileVisible: boolean;   // Whether the mean profile/baseline is visible
  baselines: any[];           // Array of frame data (each frame's baseline)
  profiles: any[];         // Array of frame data (each frame's profile)
}


interface SortingState {
  id: string;
  desc?: boolean;
}

interface ColumnVisibilityState {
  [key: string]: boolean;
}

interface FilterState {
  id: string;
  value: string;
}

interface TableState {
  sorting: SortingState[];
  columnVisibility: ColumnVisibilityState;
  columnFilters: FilterState[];
  pagination: { pageSize: number; pageIndex: number };
}

// Initial state
const initialState: TableState = {
  sorting: [],
  columnVisibility: {},
  columnFilters: [],
  pagination: { pageSize: 50, pageIndex: 0 },
};

const HomePage = (props: Props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>();
    const [Fcolumns, setFColumns] = useState(last5columns["F"]);
    const [Rcolumns, setrColumns] = useState(last5columns["R"]);
    const [RMData, setRMData] = useState<any>();
    const [RMTotal, setRMTotal] = useState<any>();
    const [FBData, setFBData] = useState<any>();
    const [FMTotal, setFMTotal] = useState<any>();
    const [RData, setRData] = useState<any[]>([]);
    const [FData, setFData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [subscription, setSubscription] = useState<any>(null);
    const [lvResult, setLvResult] = useState<any>(null);
    const [value, setValue] = useState('result');
    const [sourceValue, setSourceValue] = useState('radar')
    const [recentRows, setRecentRows] = useState<any>();
    const [latestInvoice, setLatestInvoice] = useState<any>();
    const [invoiceExpired, setInvoiceExpired] = useState<any>('false');

    const [rows, setRows] = useState<any>(() => {
      return LatestDCMTableParameterStorageService.get(LatestDCMSessionStorageKey.data) || []
    });

    const resultdata = dummyresultdata
    const frameIndex = 0

    const [graphData, setGraphData] = useState<any>({
      test_lines: [],
      cscore: '',
      cpeak: '',
      profile: [''],
      baseline: [''],
      c_color: '',
      baseline_color: '',
      profile_color: '', 
      profiles: [],
      baselines: [],
      meanProfileVisible: false,
  })

    const authData = useSelector((state: RootState) => state.auth);

    const validationSchema = () => {
      return Yup.object().shape({
      });
  }
  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
        app_build: '',
        app_version: '',
        device_model: '',
        device_uuid: '',
        full_image: '',
        id: '',
        latitude: '',
        longitude: '',
        notes: '',
        organisation_id: '',
        os_version: '',
        sample_id: '',
        sample_type: '',

        strip_image_paths: [],
        // Test lines
        baseline: '',
        cline_score: '',
        cline_peak_position: '',
        cline_peak_area: '',
        // Lines
        t_lines: [],
        tline_1_score: '',
        tline_1_area: '',
        tline_1_peak_position: '',
        tline_1_ratio: '',
        tline_2_score: '',
        tline_2_area: '',
        tline_2_peak_position: '',
        tline_2_ratio: '',
        profile: '',

        timestamp: '',
        user_id: '',
        uuid: '',
        tc_area_ratio: '',

        // User Data
        result_user_id: '',
        user_first_name: '',
        user_last_name: '',
        user_email: '',
        user_is_active: '',
        user_organisation_id: '',
        user_organisation_name: '',
        user_user_type: '',
        user_subscription_tier: '',

        camera_macro_focus_required: false,
        collection_mode: false,
        control_baseline: false,
        control_baseline_threshold: '',
        control_exposure: false,
        control_line_correction: false,
        flash_required: false,
        frames_to_capture: '',
        pmf_initial_h_scale_factor: '',
        polynomial_baseline_order: '',
        profile_alignment: false,
        profile_control_levels: '',
        reader_resolution: [], 
        test_pmf_classifier_file: '',
        test_pmf_max_conformance_probability: '',
        test_pmf_min_conformance_probability: '',        
    },
    validationSchema,
    onSubmit: (values) => {
    },
});

    useEffect(() => {
      // fetch latest result and current result count for the user
      const today = dayjs().format('YYYY-MM-DD')
      const fetchData = () => {
        const today = dayjs().format('YYYY-MM-DD')
        const data: any[] = [];

        api
        .get(`/results/dashboard/latest_non_dcm_result`, {
          headers:{
            Authorization: `Bearer ${authData.access}`,
          },
        })
        .then((res) => {
          console.log("latestresult", res.data)

          const dynamicProfiles: any[] = [];
          const dynamicBaselines: any[] = [];
    
          // Add mean profile and baseline if visible
          dynamicProfiles.push({ data: res.data.test_strips[0]?.mean_profile, color: colorConfigs.result.meanProfile, name: "Mean Profile" });
          dynamicBaselines.push({ data: res.data.test_strips[0]?.baseline, color: colorConfigs.result.meanBaseline, name: "Mean Baseline" });

          setGraphData({
            test_lines: res.data.test_strips[0]?.t_lines,
            cscore: res.data.test_strips[0]?.c_line?.score || 0,
            cpeak: res.data.test_strips[0]?.c_line?.peak_position || 0,
            c_color: res.data.test_strips[0]?.c_color,
            profile: res.data.test_strips[0]?.mean_profile,
            baseline: res.data.test_strips[0]?.baseline,
            baseline_color: colorConfigs.result.meanBaseline,
            profile_color: colorConfigs.result.meanProfile,
            profiles: dynamicProfiles, // Initialize as empty
            baselines: dynamicBaselines, // Initialize as empty
            meanProfileVisible: true
        });
        
        // Test Strips
          formik.setFieldValue('cline_score', res.data.test_strips[0].c_line.score.toFixed(2));
          formik.setFieldValue('cline_peak_position', res.data.test_strips[0].c_line.peak_position.toFixed(2));
          formik.setFieldValue('cline_area', res.data.test_strips[0].c_line.area?.toFixed(2));

          // Setting lines as an array to dynamically render these
          formik.setFieldValue('t_lines', res.data.test_strips[0].t_lines);
          
          // Dynaically sets tline score, peak and tc_ratio
          res.data.test_strips[0].t_lines.map((line:any, index:any, array:any[]) => {
          formik.setFieldValue(`tline_${array.length - index}_score`, parseFloat(line.score).toFixed(2));
          formik.setFieldValue(`tline_${array.length - index}_peak_position`, parseFloat(line.peak_position).toFixed(2));
          formik.setFieldValue(`tline_${array.length - index}_ratio`, parseFloat(line.tc_ratio).toFixed(2));
          formik.setFieldValue(`tline_${array.length - index}_area`, parseFloat(line.area)?.toFixed(2));
          const TCAreaRatio = parseFloat(line.area) / res.data.test_strips[0].c_line.area
          formik.setFieldValue(`tline_${array.length - index}_tc_area_ratio`, TCAreaRatio.toFixed(2));
          })
          
          setIsLoading(false);
        })
        .catch((err) =>{
          console.log(err);

      //     const dynamicProfiles: any[] = [];
      //     const dynamicBaselines: any[] = [];
    
      //     // Add mean profile and baseline if visible
      //     dynamicProfiles.push({ data: dummyresultdata.test_strips[0]?.mean_profile, color: colorConfigs.result.meanProfile, name: "Mean Profile" });
      //     dynamicBaselines.push({ data: dummyresultdata.test_strips[0]?.baseline, color: colorConfigs.result.meanBaseline, name: "Mean Baseline" });
          
      //     setGraphData({
      //       test_lines: dummyresultdata.test_strips[0]?.t_lines,
      //       cscore: dummyresultdata.test_strips[0]?.c_line?.score || 0,
      //       cpeak: dummyresultdata.test_strips[0]?.c_line?.peak_position || 0,
      //       c_color: "#000",
      //       profile: dummyresultdata.test_strips[0]?.mean_profile,
      //       baseline: dummyresultdata.test_strips[0]?.baseline,
      //       baseline_color: colorConfigs.result.meanBaseline,
      //       profile_color: colorConfigs.result.meanProfile,
      //       profiles: dynamicProfiles, // Initialize as empty
      //       baselines: dynamicBaselines, // Initialize as empty
      //       meanProfileVisible: true
      //   });
        
      //   // Test Strips
      //   formik.setFieldValue('cline_score', dummyresultdata.test_strips[0].c_line.score.toFixed(2));
      //   formik.setFieldValue('cline_peak_position', dummyresultdata.test_strips[0].c_line.peak_position.toFixed(2));
      //   formik.setFieldValue('cline_area', dummyresultdata.test_strips[0].c_line.area?.toFixed(2));

      //   // Setting lines as an array to dynamically render these
      //   formik.setFieldValue('t_lines', dummyresultdata.test_strips[0].t_lines);
        
      //   // Dynaically sets tline score, peak and tc_ratio
      //   dummyresultdata.test_strips[0].t_lines.map((line:any, index:any, array:any[]) => {
      //   formik.setFieldValue(`tline_${array.length - index}_score`, parseFloat(line.score).toFixed(2));
      //   formik.setFieldValue(`tline_${array.length - index}_peak_position`, parseFloat(line.peak_position).toFixed(2));
      //   formik.setFieldValue(`tline_${array.length - index}_ratio`, parseFloat(line.tc_ratio).toFixed(2));
      //   formik.setFieldValue(`tline_${array.length - index}_area`, parseFloat(line.area)?.toFixed(2));
      //   const TCAreaRatio = parseFloat(line.area) / dummyresultdata.test_strips[0].c_line.area
      //   formik.setFieldValue(`tline_${array.length - index}_tc_area_ratio`, TCAreaRatio.toFixed(2));
      // })

      // setIsLoading(false);
        })
      
      api
      .get('/results/?limit=10&dcm=true', {
        headers:{
          Authorization: `Bearer ${authData.access}`,
        },
      })
      .then((res) => {
        console.log("latestresult", res.data)
        setRows(res.data.results)

      }).catch((err) =>{
        console.log(err);
      })

      api
      .get('/results/views', {
        headers:{
          Authorization: `Bearer ${authData.access}`,
        },
      })
      .then((res) => {
        console.log("recentresults", res.data)
        setRecentRows(res.data.results)

      }).catch((err) =>{
        console.log(err);
      })

      api
      .get('/payments/invoices/', {
        headers:{
          Authorization: `Bearer ${authData.access}`,
        },
      })
      .then((res) => {
        console.log("latestinvoice", res.data)
        setLatestInvoice(res.data.results[0].current_period_end)
        let invoicedatediff = new Date().getTime() - latestInvoice
          if (invoicedatediff > 0) {
          setInvoiceExpired('true')
        }

      }).catch((err) =>{
        console.log(err);
      })

      
      };

      fetchData()
      dispatch(refreshAccessToken());
    }, [authData.access]);

    const handleTabsChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };

    const handleSourceTabsChange = (event: React.SyntheticEvent, newValue: string) => {
      setSourceValue(newValue);
      console.log(sourceValue)
    };

    return authData.isFreeUser ?  (<Navigate to="/account/subscription"/>) : (
      <div>
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
          <Grid2 direction='column' alignItems='center' container spacing={1} sx={{ width: 580, pr:1}}>
            <Grid2 size={12}>
              <Card sx={{width:'100%', height: 680}}>
              <Tabs
                value={value}
                onChange={handleTabsChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value="result" label="Result" />
                <Tab value="dcm" label="DCM" />
              </Tabs>
              {value == 'result' ?
              <>
              <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                  Latest Result
                </Typography>
              <Collapse in={graphData.test_lines.length > 0 } sx={{height:'100%'}}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <ResultDetailGraph graphdata={graphData} showGraph={true}/> 
                </Box>
                <FormikProvider value={formik}>

                <Table sx={{width: '100%', height: 'auto' }}>
                  <tbody>
                  {graphData.test_lines.map((line:any, index:any) => (
                    <TableRow key={index} sx={{ p: 0, m: 0}}>
                        <TableCell sx={{ p: 0, m: 0}}>
                            <Box sx={{mx: {xs: 1, sm: 1, md: 1, lg: 3}}}>
                                <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: "#000", background: line.t_color, p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                                    Test&nbsp;line&nbsp;{index + 1}
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell sx={{ p: 0, m: 0 }}>
                            <Field name={`tline_${index + 1}_peak_position`} type="text" className="form-control" label="Position" component={ResultDetailTableDashboardField} color={line.t_color} />                                            
                        </TableCell>
                        <TableCell sx={{ p: 0, m: 0 }}>
                            <Field name={`tline_${index + 1}_score`} type="text" className="form-control" label="Height" component={ResultDetailTableDashboardField} color={line.t_color} />
                        </TableCell>
                        <TableCell sx={{ p: 0, m: 0 }}>
                            <Field name={`tline_${index + 1}_area`} type="text" className="form-control" label="Area" component={ResultDetailTableDashboardField} color={line.t_color} />
                        </TableCell>
                        <TableCell sx={{ p: 0, m: 0 }}>
                            <Field name={`tline_${index + 1}_tc_area_ratio`} type="text" className="form-control" label="T/C Height" component={ResultDetailTableDashboardField} color={line.t_color} />
                        </TableCell>
                        <TableCell sx={{ p: 0, m: 0 }}>
                            <Field name={`tline_${index + 1}_ratio`} type="text" className="form-control" label="T/C Area" component={ResultDetailTableDashboardField} color={line.t_color} />
                        </TableCell>
                    </TableRow>
                    
                ))}
                  <TableRow sx={{ p: 0, m: 0 }}>
                    <TableCell sx={{ p: 0, m: 0}}>
                            <Box sx={{mx: {xs: 1, sm: 1, md: 1, lg: 3}}}>
                                <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: "#000", background: "#fff", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                                    Control&nbsp;line
                                </Typography>
                            </Box>
                    </TableCell>
                    <TableCell sx={{ p: 0, m: 0}}>
                        <Field name="cline_peak_position" type="text" className="form-control" label="Position" component={ResultDetailTableDashboardField} color={"#fff"} />                                            
                    </TableCell>
                    <TableCell sx={{ p: 0, m: 0 }}>
                        <Field name="cline_score" type="text" className="form-control" label="Height" component={ResultDetailTableDashboardField} color={"#fff"} />                                            
                    </TableCell>
                    <TableCell sx={{ p: 0, m: 0 }}>
                        <Field name="cline_area" type="text" className="form-control" label="Area" component={ResultDetailTableDashboardField} color={"#fff"} />                                            
                    </TableCell>
                    <TableCell sx={{ p: 0, m: 0 }}>-</TableCell>
                    <TableCell sx={{ p: 0, m: 0 }}>-</TableCell>
                  </TableRow>
                </tbody>
              </Table>
              </FormikProvider>
              </Collapse>
              </>
                : value == 'dcm' ?
              <>
              <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                  Latest DCM
                </Typography>
                <RecentDCMResultsTable 
                  authData={authData}
                  rows={rows} />
              </>
                : null
              } 
                  
                <Card>

                </Card>             
              </Card>
            </Grid2>
            <Grid2 size={12}>
              <Card sx={{height: 320, alignItems: 'center', justifyContent:"center"}}>
              <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                  Recently viewed
                </Typography>
                <LastFiveResultsTable 
                authData={authData}
                rows={recentRows} />  
              </Card>
            </Grid2>
          </Grid2>
          <Grid2 direction='column' alignItems='center' container spacing={1} sx={{ width: 580, pr:1}}>
            <Grid2 size={12} alignItems='center'>
              <Card sx={{height: 500, alignItems: 'center', justifyContent:"center"}}>
              <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                  Tests
                </Typography>
              <TestsGraph/>  
            </Card>
            </Grid2>
            <Grid2 size={12} alignItems='center'>
              <Card sx={{height: 500, alignItems: 'center', justifyContent:"center"}}>
              <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                  Result Source
                </Typography>
                <Tabs
                value={sourceValue}
                onChange={handleSourceTabsChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value="radar" label="Radar" />
                <Tab value="radial" label="Radial" />
              </Tabs>
              {sourceValue == 'radar' ?
                <ResultSourceRadarGraph/>
                : sourceValue == 'radial' ?
                <ResultSourceRadialGraph/>
                : null }
              </Card>
            </Grid2>
          </Grid2>
          <Grid2 direction='column' alignItems='center' container spacing={1} sx={{ width: 580}}>
            <Grid2 size={12} alignItems='center'>
              <Card sx={{height: 700, alignItems: 'center', justifyContent:"center"}} >
                <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                  Usage
                </Typography>
                <Table sx={{ width: '100%', height: 'auto', [`& .${tableCellClasses.root}`]: { borderBottom: "none"} }}>
                  <TableRow>
                    <TableCell>
                    <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                      Tier
                    </Typography>
                    </TableCell>
                    <TableCell/>
                    <TableCell> 
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#008000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        {authData.tier}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell>
                    <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                      Next Invoice
                    </Typography>
                  </TableCell>
                  <TableCell/>
                  <TableCell> 
                    <Typography color= {invoiceExpired == 'true' ? '#FF0000' : latestInvoice? "#008000" : '#FF0000'} sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'right', minWidth: '100%', fontWeight: 1000, p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                      {latestInvoice? latestInvoice : 'N/A'} 
                    </Typography>
                  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        Housings
                      </Typography>
                    </TableCell>
                    <TableCell/>
                    <TableCell> 
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#008000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        1/3
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        Methods
                      </Typography>
                    </TableCell>
                    <TableCell/>
                    <TableCell> 
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#008000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        2/3 
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        Users
                      </Typography>
                    </TableCell>
                    <TableCell/>
                    <TableCell> 
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#008000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        1/3 
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        Processing Units
                      </Typography>
                    </TableCell>
                    <TableCell/>
                    <TableCell> 
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#FFA500", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        285/300 
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        Data Capacity
                      </Typography>
                    </TableCell>
                    <TableCell/>
                    <TableCell> 
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#008000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        145MB/450MB 
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'left', minWidth: '100%', fontWeight: 1000, color: "#000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        No. of Results
                      </Typography>
                    </TableCell>
                    <TableCell/>
                    <TableCell> 
                      <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'right', minWidth: '100%', fontWeight: 1000, color: "#008000", p: 1, paddingX: 1.5, margin: 'auto', borderRadius: 1}}>
                        230
                      </Typography>
                    </TableCell>
                  </TableRow>

                </Table>

              </Card>
            </Grid2>
            <Grid2 size={12}>
              <Card sx={{height: 300}}>
              <Typography sx={{fontSize: fontConfigs.detailPage.headings.title, textAlign: 'center', minWidth: '100%', fontWeight: 1000, color: "#000", pt: 1, margin: 'auto', borderRadius: 1}}>
                  Usage Table
                </Typography>
                <UsageGraph/>
              </Card>
            </Grid2>
          </Grid2>
        </div>
        {/* <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
          <Grid container spacing={1} sx={{height: '100px', width: '70%'}}>
            <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Card sx={{mt: '1%', pt:'2%', height: '100px', background: colorConfigs.sub_banner.secondary_sub}}>
              <Tooltip title="Upgrade your subscription!">
                <Link 
                  href="/admin" 
                  underline="none" 
                  sx={{
                    color: 'white',
                    fontSize: '24px',
                    pt: '1.5%',
                  }}>
                  Subscription Tier
                </Link>
              </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={4} sx={{fontSize: '18px', textAlign: 'center'}}>
              <Card sx={{ backgroundColor: colorConfigs.sub_banner.sub }}>
                <Tooltip title="Go to your Admin panel">
                  <Link 
                    href="/admin" 
                    underline="none" 
                    sx={{
                      color: 'white',
                      fontSize: '24px',
                      pt: '1.5%',
                    }}>
                    Organisation_Name
                  </Link>
                </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={4} sx={{fontSize: '18px', textAlign: 'center'}}>
              <Card sx={{ backgroundColor: colorConfigs.sub_banner.sub }}>
                <Tooltip title="Add more users">
                    <Link 
                      href="/admin" 
                      underline="none" 
                      sx={{
                        color: 'white',
                        fontSize: '24px',
                        pt: '1.5%',
                      }}>
                      Users/MaxUsers
                    </Link>
                  </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={4} sx={{fontSize: '18px', textAlign: 'center'}}>
              <Card sx={{ backgroundColor: colorConfigs.sub_banner.sub }}>
                <Tooltip title="Increase your maximum monthly results">
                  <Link 
                    href="/admin" 
                    underline="none" 
                    sx={{
                      color: 'white',
                      fontSize: '24px',
                      pt: '1.5%',
                    }}>
                    Results/MaxResults
                  </Link>
                </Tooltip>
              </Card>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                  <Card sx={{height:'30vh', background: colorConfigs.sub_banner.secondary_sub}}>
                    <Typography variant="body2" color="text.secondary" align="center">
                          Latest results
                    </Typography>
                    <LastFiveRList
                        columns={Rcolumns}
                        data={RData}
                        isLoading={isLoading}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={4}>
                  <Card sx={{height:'30vh', background: colorConfigs.sub_banner.secondary_sub}}>
                    <Typography variant="body2" color="text.secondary" align="center" sx={{p:1, fontSize: "20px"}}>
                        Today's Results
                    </Typography>
                      <RadialGraph total={RMTotal} data={RMData}/>
                  </Card>
                  </Grid>
                  <Grid item xs={4}>
                  <Card sx={{height:'30vh', background: colorConfigs.sub_banner.secondary_sub}}>
                    <Typography variant="body2" color="text.secondary" align="center">
                          Users
                    </Typography>
                    <LastFiveRList
                        columns={Rcolumns}
                        data={RData}
                        isLoading={isLoading}
                      />
                  </Card>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{height:'35vh', background: colorConfigs.sub_banner.secondary_sub}}>
                <LineGraph data={[]}/>
              </Card>
            </Grid>
          </Grid>
        </div> */}
      </div>
   ) 
};

export default HomePage;